/* eslint-disable no-lone-blocks */
import { useLocation } from "react-router-dom";
import swal from "sweetalert";
import Swal from "sweetalert2";
import {
  formatError,
  login,
  prologin,
  prosaveTokenInLocalStorage,
  prosignUp,
  runLogoutTimer,
  saveTokenInLocalStorage,
  signUp,
} from "../../services/AuthService";

export const SIGNUP_CONFIRMED_ACTION = "[signup action] confirmed signup";
export const SIGNUP_FAILED_ACTION = "[signup action] failed signup";
export const LOGIN_CONFIRMED_ACTION = "[login action] confirmed login";
export const LOGIN_FAILED_ACTION = "[login action] failed login";
export const LOADING_TOGGLE_ACTION = "[Loading action] toggle loading";
export const LOGOUT_ACTION = "[Logout action] logout action";

export function signupAction(
  email,
  password,
  password_confirmation,
  last_name,
  first_name,
  history
) {
  return (dispatch) => {
    signUp(email, password, password_confirmation, last_name, first_name)
      .then((response) => {
        console.log(response);
        saveTokenInLocalStorage(response.data);
        runLogoutTimer(dispatch, response.data.expiry * 1000, history);
        // swal("Good Job", "SignUp SuccessFully", "success");
        dispatch(confirmedSignupAction(response.data));
        setTimeout(() => {
          localStorage.setItem("Counting", 1);
          history.push("/login");
        }, 1000);
      })
      .catch((errors) => {
        console.log(errors?.response?.data?.errors[0]);
        const errorMessage = errors?.response?.data?.errors[0];
        swal({
          icon: "error",
          title: errorMessage,
        });
        console.log(errorMessage);
        dispatch(signupFailedAction(errorMessage));
      });
  };
}
export function ProsignupAction(
  email,
  password,
  password_confirmation,
  role,
  address,
  business_type,
  phone_number,
  user_date,
  history
) {
  return (dispatch) => {
    prosignUp(
      email,
      password,
      password_confirmation,
      role,
      address,
      business_type,
      phone_number,
      user_date
    )
      .then((response) => {
        console.log(response);
        prosaveTokenInLocalStorage(response.data);
        runLogoutTimer(dispatch, response.data.expiry * 1000, history);
        swal(response.data.message, "success");
        dispatch(proconfirmedSignupAction(response.data));
        history.push("/professional-login");
      })
      .catch((errors) => {
        console.log(errors?.response?.data?.errors[0]);
        const errorMessage = errors?.response?.data?.errors[0];
        swal({
          icon: "error",
          title: errorMessage,
        });
        console.log(errorMessage);
        dispatch(prosignupFailedAction(errorMessage));
      });
  };
}

export function logout(history) {
  localStorage.clear();
  history.push("/");
  return {
    type: LOGOUT_ACTION,
  };
}

export function loginAction(email, password, history) {
  return (dispatch) => {
    login(email, password)
      .then((response) => {
        console.log(response);
        saveTokenInLocalStorage(response?.data);
        runLogoutTimer(dispatch, 10000 * 1000, history);
        if (response.data.status === true) {
          localStorage.setItem("Count", 1);

          dispatch(loginConfirmedAction(response?.data));

          // swal("Good job!", "Login SuccessFully", "success");
          {
            response?.data?.data?.package === "Unpaid"
              ? history.push("/payment")
              : response?.data?.data?.agree === "true"
              ? history.push(`/FeedBackForm/${response?.data?.data?.id}`)
              : history.push(`/profileedit/${response?.data?.data?.id}`);
          }
        } else if (response.data.status === false) {
          // eslint-disable-next-line no-lone-blocks
          {
            response?.data?.message
              ? swal({
                  icon: "error",
                  title: response?.data?.message,
                })
              : swal({
                  icon: "error",
                  title: response?.data?.errors?.email[0],
                });
          }
          dispatch(loginFailedAction(response?.data));
        }
      })
      .catch((error) => {
        const errorMessage = error?.response?.data?.errors;
        {
          errorMessage
            ? swal({
                icon: "error",

                title: errorMessage,
              })
            : swal({
                icon: "error",

                title: "Connection Error",
              });
        }

        dispatch(loginFailedAction(errorMessage));
      });
  };
}
export function prologinAction(email, password, history) {
  return (dispatch) => {
    prologin(email, password)
      .then((response) => {
        console.log(response);
        prosaveTokenInLocalStorage(response.data);
        runLogoutTimer(dispatch, 10000 * 1000, history);
        dispatch(loginConfirmedAction(response.data));
        swal("Good job!", response.data.message, "success");

        history.push("/");
      })
      .catch((error) => {
        console.log(error?.response?.data?.message);
        const errorMessage = error?.response?.data?.message;
        {
          errorMessage
            ? swal({
                icon: "error",

                title: errorMessage,
              })
            : swal({
                icon: "error",

                title: "Connection Error",
              });
        }

        dispatch(loginFailedAction(errorMessage));
      });
  };
}

export function loginFailedAction(data) {
  return {
    type: LOGIN_FAILED_ACTION,
    payload: data,
  };
}

export function loginConfirmedAction(data) {
  return {
    type: LOGIN_CONFIRMED_ACTION,
    payload: data,
  };
}

export function confirmedSignupAction(payload) {
  return {
    type: SIGNUP_CONFIRMED_ACTION,
    payload,
  };
}
export function proconfirmedSignupAction(payload) {
  return {
    type: SIGNUP_CONFIRMED_ACTION,
    payload,
  };
}

export function signupFailedAction(message) {
  return {
    type: SIGNUP_FAILED_ACTION,
    payload: message,
  };
}
export function prosignupFailedAction(message) {
  return {
    type: SIGNUP_FAILED_ACTION,
    payload: message,
  };
}

export function loadingToggleAction(status) {
  return {
    type: LOADING_TOGGLE_ACTION,
    payload: status,
  };
}
