import React, { useEffect } from "react";
import Header from "../Layout/Header";

const Terms = () => {
  const isVisited = () => localStorage.setItem("visited", true);
  useEffect(() => {
    isVisited();
  }, []);

  return (
    <>
      <Header />
      <div className="container">
        <div className="row my-5">
          <div className="col-md-12">
            <h3 className="text-center my-5">
              <u>Terms And Conditions</u>
            </h3>
            <div className="my-5">
              <h5>
                Living Ayurveda Resources Int., Inc. (referred to as ‘The
                Company’), is registered in Delaware and owns the content of The
                30 Day Weight Loss Program Online.
                <br />
                <br />
                <br />
                The Company will use your data to manage your successful weight
                loss. We will not pass your data on to any third party. We will
                not in an attempt to sell you any other
                products or services from third parties.
                <br />
                <br />
                All our staff and contractors sign an agreement not to disclose
                any information about our weight loss customers to any other
                company or person.
                <br />
                <br />
                Our website and data systems are also protected from hackers and
                other internet security breaching systems that attempt to access
                or copy our data.
                <br />
                <br />
                By ticking this box you agree to let our staff and contractors
                read your daily weight loss records to assist in your successful
                weight loss and improved energy, clarity of mind and happiness.
                <br />
                <br />
              </h5>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Terms;
