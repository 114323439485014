/* eslint-disable jsx-a11y/img-redundant-alt */
import React from "react";
import { Link, useHistory } from "react-router-dom";
import Header from "./../Layout/Header";
import Footer from "./../Layout/Footer";
import Videothree from "./Videothree";
import Videotwo from "./Videotwo";
import Swal from "sweetalert2";
import Homevideo from "./HomeVideo";
import axios from "axios";
import { useState } from "react";
import { useEffect } from "react";
import Logout from "../Layout/Logout";
import VideoMain from "./VideoMain";
import VideoBotright from "./VideoBotright";

//Images

var bg = require("../../images/Wall.png");
var he = require("../../images/he.png");
function Homepage() {
  const isLogin = localStorage.getItem("userDetails");
  const history = useHistory();
  let store2 = localStorage.getItem("userDetails");
  store2 = JSON.parse(store2);
  const [cookies, setCookies] = useState("");

  const CookiAccpeted = () => {
    let token = localStorage.getItem("userDetails");
    token = JSON.parse(token);
    var config = {
      method: "post",
      url: `https://admin.the30dayweightloss.co.uk/api/user/cookies/${store2?.data?.id}`,
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${token.token}`,
      },
    };

    axios(config)
      .then(function (response) {
        console.log(response);
        setCookies(response?.data?.cookie?.cookies);
        if (response?.data?.status === true) {
          console.log("working");
          Profile();
        } else {
        }
      })
      .catch((errors) => {
        Swal.fire({
          // title: "Oppss!",
          text: errors,
          icon: "error",
          confirmButtonText: "Logout",
          confirmButtonColor: "red",
        }).then((result) => {
          if (result.isConfirmed) {
            localStorage.clear();
            setTimeout(() => {
              localStorage.clear();
              history.push("/login");
              window.location.reload();
            }, 5000);
          }
        });
      });
  };
  // For Getting Reponse i Use  Api
  const [getprofile, setGetProfile] = useState("");
  const Profile = () => {
    let token = localStorage.getItem("userDetails");
    token = JSON.parse(token);
    var config = {
      method: "get",
      url: `https://admin.the30dayweightloss.co.uk/api/user/view/profile/${store2?.data?.id}`,
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${token.token}`,
      },
    };

    axios(config)
      .then(function (response) {
        setGetProfile(response?.data?.data?.cookies);
        console.log(getprofile);
        console.log(response?.data?.data?.cookies, "checking");
        localStorage.setItem("cookies", response?.data?.data?.cookies);
        if (response?.data?.status === true) {
        } else {
          Swal.fire({
            // title: "Oppss!",
            text: "Connection Failed!!",
            icon: "error",
            button: "Ok",
          });
        }
      })
      .catch((errors) => {
        Swal.fire({
          // title: "Oppss!",
          text: errors,
          icon: "error",
          confirmButtonText: "Logout",
          confirmButtonColor: "red",
        }).then((result) => {
          if (result.isConfirmed) {
            localStorage.clear();
            setTimeout(() => {
              localStorage.clear();
              history.push("/login");
              window.location.reload();
            }, 5000);
          }
        });
        console.log(errors, "=========>errors");
      });
  };

  useEffect(() => {
    if (isLogin) {
      Profile();
    }
  }, []);
  const [content, setContent] = useState();
  const Home_Content = () => {
    // setLoader(true);
    var config = {
      method: "get",
      url: `https://admin.the30dayweightloss.co.uk/api/user/home/content`,
    };

    axios(config)
      .then(function (response) {
        setContent(response?.data?.data);

        if (response?.data?.status === true) {
          window.scrollTo({
            top: 0,
            behavior: "smooth",
          });
        } else {
          console.log(response);
        }
      })
      .catch(function (error) {
        console.log(error?.response);

        Swal.fire({
          showCloseButton: true,
          toast: true,
          icon: "error",
          title: "asdfasdsad",
          animation: true,
          position: "top-right",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.addEventListener("mouseenter", Swal.stopTimer);
            toast.addEventListener("mouseleave", Swal.resumeTimer);
          },
        });
      });
  };
  useEffect(() => {
    Home_Content();
  }, [1]);

  useEffect(() => {
    if (getprofile === "Unaccepted") {
      Swal.fire({
        title: "  ",
        position: "bottom",
        width: "100%",
        text: "We use cookies on our website to give you the most relevant experience by remembering your preferences and repeat visits. By clicking 'Yes, I accept cookies', you consent to the use of all the cookies.",
        showDenyButton: true,
        confirmButtonText: "Yes, I accept cookies",
        denyButtonText: "Don't accept",
      }).then((result) => {
        if (result.isConfirmed) {
          CookiAccpeted();
        }
      });
    } else {
    }
  }, [getprofile]);
  // For Getting Reponse i Use same Api
  // For Video Gallery
  const [first, setFirst] = useState(true);
  const [sec, setSec] = useState(false);
  const [thr, setThr] = useState(false);
  const [fori, setFori] = useState(false);
  const [fiv, setFiv] = useState(false);

  const one = () => {
    setFirst(true);
    setSec(false);
    setThr(false);
  };
  const two = () => {
    setFirst(false);
    setSec(true);
    setThr(false);
  };
  const three = () => {
    setFirst(false);
    setSec(false);
    setThr(true);
  };

  // For Video Gallery End
  return (
    <div className="page-wraper">
      <Header />
      <div className="page-content">
        <div className="container-fluid  my-3">
          <div className="container-fluid" style={{ maxWidth: "90%" }}>
            <h2 className="text-center mt-5 font">
              {" "}
              The 30 Day Weight Loss Program Online
            </h2>
            <div className="row ">
              <div className="col-md-6">
                <p
                  className="text-black font"
                  style={{ lineHeight: 2, fontSize: "1.5em" }}
                >
                  {content?.content_1 ? content?.content_1 : "No Content FOund"}
                </p>
              </div>
              <div className="col-md-6">
                <p
                  className="text-black font"
                  style={{ lineHeight: 2, fontSize: "1.5em" }}
                >
                  {content?.content_2 ? content?.content_2 : "No Content Found"}
                </p>
              </div>
            </div>
          </div>
        </div>

        <div
          className="container-fluid"
          style={{ maxWidth: "95%", marginTop: -35 }}
        >
          <div className="row">
            <div className="col-md-6">
              <div className="my-5 ">
                {first ? <VideoMain /> : null}
                {sec ? <VideoBotright /> : null}
                {thr ? <Homevideo /> : null}
              </div>
            </div>
            <div className="col-md-3 ">
              <div className="my-5" style={{}}>
                <Link to="#" onClick={() => one()}>
                  <h6 className={first ? "site-button mt-2" : "mt-2"}>
                    1: 30 Day Weight Loss Program Course Outline
                  </h6>
                </Link>

                <Link to="#" onClick={() => two()}>
                  <h6 className={sec ? "site-button mt-3" : "mt-3"}>
                    2: Pitta Wrap with Vegetables & Avocado
                  </h6>
                </Link>

                <Link to="#" onClick={() => three()}>
                  <h6 className={thr ? "site-button mt-3" : "mt-3"}>
                    3: The Mind-BodyType Questionnaire
                  </h6>
                </Link>
              </div>
            </div>
            <div className="col-md-3 my-5">
              <img src={he} alt="no image" className="w-100" />
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}
export default Homepage;
