import { applyMiddleware, combineReducers, compose, createStore } from "redux";
import PostsReducer from "./reducers/PostsReducer";
import thunk from "redux-thunk";
import { AuthReducer } from "./reducers/AuthReducer";
import todoReducers from "./reducers/Reducers";
import { reducer as reduxFormReducer } from "redux-form";
import storage from "redux-persist/lib/storage";
import { persistStore, persistReducer } from "redux-persist";
const middleware = applyMiddleware(thunk);

const reducers = combineReducers({
  posts: PostsReducer,
  auth: AuthReducer,
  todoReducers,
  form: reduxFormReducer,
});

const persistConfig = {
  key: "root",
  storage,
  whitelist: ["auth"],
};

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const persistedReducer = persistReducer(persistConfig, reducers);

//const store = createStore(rootReducers);

// export const store = createStore(persistedReducer,  composeEnhancers(middleware));
// export default () => {
//   }
let store = createStore(persistedReducer, applyMiddleware(thunk));
let persistor = persistStore(store);
export { store, persistor };
