import React from "react";
import { Link } from "react-router-dom";

function Jobcategories() {
  return (
    <div className="row sp20">
      <div className="col-lg-4 col-md-6 col-sm-6">
        <div className="icon-bx-wraper">
          <div className="icon-content">
            <div className="icon-md text-primary m-b20">
              <i className="ti-location-pin"></i>
            </div>
            <Link to={"/Attorney"} className="dez-tilte">
              Attorney
            </Link>
            <p className="m-a0">198 Open Positions</p>
            <div className="rotate-icon">
              <i className="ti-location-pin"></i>
            </div>
          </div>
        </div>
      </div>
      <div className="col-lg-4 col-md-6 col-sm-6">
        <div className="icon-bx-wraper">
          <div className="icon-content">
            <div className="icon-md text-primary m-b20">
              <i className="ti-wand"></i>
            </div>
            <Link to={"/Advisor"} className="dez-tilte">
              Advisor
            </Link>
            <p className="m-a0">198 Open Positions</p>
            <div className="rotate-icon">
              <i className="ti-wand"></i>
            </div>
          </div>
        </div>
      </div>
      <div className="col-lg-4 col-md-6 col-sm-6">
        <div className="icon-bx-wraper">
          <div className="icon-content">
            <div className="icon-md text-primary m-b20">
              <i className="ti-wallet"></i>
            </div>
            <Link to={"/Accoctant"} className="dez-tilte">
              Accountant
            </Link>
            <p className="m-a0">198 Open Positions</p>
            <div className="rotate-icon">
              <i className="ti-wallet"></i>
            </div>
          </div>
        </div>
      </div>

      {/* <div className="col-lg-12 text-center m-t30">
				<button className="site-button radius-xl">All Categories</button>
			</div> */}
    </div>
  );
}

export default Jobcategories;
