import React, { Component } from "react";
import { Link, useLocation } from "react-router-dom";
import { Modal } from "react-bootstrap";
import Logout from "./Logout";

import logo2 from "./../../images/weight.png";
import { useEffect } from "react";
import { useState } from "react";
import { isAuthenticated } from "../../store/selectors/AuthSelectors";
import axios from "axios";
var bnr3 = require("./../../images/background/bg3.jpg");

export const Header = () => {
  const [getprofile, setGetProfile] = useState("");
  const Profile = () => {
    var config = {
      method: "get",
      url: `https://admin.the30dayweightloss.co.uk/api/user/twenty/users`,
    };

    axios(config)
      .then(function (response) {
        setGetProfile(response?.data?.data?.total);
        console.log(getprofile);
        console.log(response?.data?.data?.total, "checking");
        if (response?.data?.status === true) {
        } else {
        }
      })
      .catch((errors) => {
        console.log(errors, "=========>errors");
      });
  };

  useEffect(() => {
    Profile();
  }, []);
  const isLogin = localStorage.getItem("userDetails");
  const isPaid = localStorage.getItem("Payment");
  let store2 = localStorage.getItem("userDetails");
  store2 = JSON.parse(store2);
  const location = useLocation();
  const home = location.pathname === `/` ? "active" : "";
  const videos = location.pathname === `/30daysweightlossplan` ? "active" : "";
  const pdf = location.pathname === `/Plans` ? "active" : "";
  const Mangement =
    location.pathname === `/profile/${store2?.data?.id}` ? "active" : "";
  useEffect(() => {
    // sidebar open/close

    var Navicon = document.querySelector(".navicon");
    var sidebarmenu = document.querySelector(".myNavbar ");

    function toggleFunc() {
      sidebarmenu.classList.toggle("show");
      // Navicon.classList.toggle('open');
    }
    Navicon.addEventListener("click", toggleFunc);

    // Sidenav li open close
    var navUl = [].slice.call(
      document.querySelectorAll(".navbar-nav > li > a, .sub-menu > li > a")
    );
    for (var y = 0; y < navUl.length; y++) {
      navUl[y].addEventListener("click", function () {
        checkLi(this);
      });
    }

    function checkLi(current) {
      current.parentElement.parentElement
        .querySelectorAll("li")
        .forEach((el) =>
          current.parentElement !== el ? el.classList.remove("open") : ""
        );
      setTimeout(() => {
        current.parentElement.classList.toggle("open");
      }, 100);
    }
  });

  return (
    <>
      <header className="site-header mo-left header fullwidth">
        <div
          className="sticky-header main-bar-wraper navbar-expand-lg"
          style={{ position: "fixed", width: "100%", top: 0 }}
        >
          <div className="main-bar clearfix">
            <div
              className="container-fluid clearfix"
              style={{ maxWidth: "95%" }}
            >
              <div className="logo-header mostion">
                <Link to={"/"}>
                  <img
                    src={logo2}
                    className="logo mb-1"
                    style={{ height: 80, maxWidth: 350 }}
                    alt="img"
                  />
                </Link>
              </div>

              <button
                className="navbar-toggler collapsed navicon  justify-content-end"
                type="button"
                data-toggle="collapse"
                data-target="#navbarNavDropdown"
                aria-controls="navbarNavDropdown"
                aria-expanded="false"
                aria-label="Toggle navigation"
              >
                <span></span>
                <span></span>
                <span></span>
              </button>
              <div className="extra-nav">
                {isLogin ? (
                  <div className="extra-cell">
                    <div class="dropdownbtn">
                      <button class="site-button text-capitalize">
                        <i className="fa fa-user"></i>
                        &nbsp;
                        {store2?.data?.first_name} &nbsp;
                      </button>
                      <div class="dropdown-content">
                        <Logout class="site-button my-2" />
                        <Link
                          to={"/Reset-Password"}
                          title="Reset Password"
                          class="site-button my-2"
                        >
                          <i className="fa fa-edit" aria-hidden="true"></i>
                          &nbsp;
                          <span>Change Password</span>&nbsp;
                        </Link>
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className="extra-cell">
                    {getprofile === "0" ? null : (
                      <Link to={"/Payment-New"} className="site-button">
                        <i className="fa fa-tag"></i>
                        &nbsp; 65% off for {getprofile} Clients Only
                      </Link>
                    )}

                    <Link to={"/login"} className="site-button">
                      <i className="fa fa-user"></i>
                      &nbsp; Login
                    </Link>
                  </div>
                )}
              </div>

              <div
                className="header-nav navbar-collapse collapse myNavbar justify-content-start"
                id="navbarNavDropdown"
              >
                <div className="logo-header mostion d-md-block d-lg-none">
                  <Link to={"/"} className="dez-page">
                    <img
                      src={logo2}
                      alt=""
                      style={{ height: 80, maxWidth: 350 }}
                    />
                  </Link>
                </div>
                <ul className="nav navbar-nav">
                  <li className={home}>
                    <Link to="/" className="">
                      Home
                    </Link>
                  </li>
                  {isLogin ? (
                    <>
                      <li className={videos}>
                        <Link to="/30daysweightlossplan" className={videos}>
                          Videos
                        </Link>
                      </li>
                      <li className={pdf}>
                        <Link to="/Plans" className={pdf}>
                          Meal Plans
                        </Link>
                      </li>
                      <li className={Mangement}>
                        <Link to={"#"}>
                          Manage Program &nbsp;
                          <i className="fa fa-chevron-down"></i>
                        </Link>{" "}
                        <ul className="sub-menu">
                          <li>
                            <Link
                              to={`/profile/${store2?.data?.id}`}
                              className="dez-page"
                            >
                              <i
                                className="fa fa-user-o"
                                aria-hidden="true"
                              ></i>
                              <span>Profile</span>
                            </Link>
                          </li>
                          <li>
                            <Link
                              to={`/FeedBackForm/${store2.data?.id}`}
                              className="dez-page"
                            >
                              <i
                                className="fa fa-wpforms"
                                aria-hidden="true"
                              ></i>
                              <span>Daily FeedBack Form</span>
                            </Link>
                          </li>
                          <li>
                            <Link to={"/Feedback"} className="dez-page">
                              <i
                                className="fa fa-comments-o"
                                aria-hidden="true"
                              ></i>
                              <span>Daily FeedBack Chart</span>
                            </Link>
                          </li>
                          <li>
                            <Link to={"/contact"} className="dez-page">
                              <i className="fa fa-gears" aria-hidden="true"></i>
                              <span>Support</span>
                            </Link>
                          </li>
                        </ul>
                      </li>
                    </>
                  ) : null}

                  <li>
                    <Link to={"/Aboutus"}>
                      About us &nbsp;
                      <i className="fa fa-chevron-down"></i>
                    </Link>
                    <ul className="sub-menu">
                      <li>
                        <Link to={"/contact"} className="dez-page">
                          <i className="fa fa-gears" aria-hidden="true"></i>
                          <span>Contact Us</span>
                        </Link>
                      </li>
                      <li>
                        <Link to={"/PP"} className="dez-page">
                          <i className="fa fa-shield" aria-hidden="true"></i>
                          <span>Privacy Policy</span>
                        </Link>
                      </li>
                    </ul>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </header>
      <br />
      <br />
    </>
  );
};
export default Header;
