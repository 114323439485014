import React, { Component } from "react";
import Slider from "react-slick";

const postBlog = [
  {
    image: require("./../../images/testimonials/pic1.jpg"),
    text: "The wraps are super delicious, nutritious, and easy to make, ideal for snacks or light meals with soup or salad. I love them.",
    name: "J.C.",
  },
  {
    image: require("./../../images/testimonials/pic2.jpg"),
    text: "The vegetable asparagus soup is so creamy, soothing, and delicious – easily digestible and nourishing, yet satisfying for a light dinner.",
    name: "A.C.",
  },
  {
    image: require("./../../images/testimonials/pic3.jpg"),
    text: "The recipe for Vegetable Paneer is sublime. The flavors are complex yet subtle – 5 stars!",
    name: "C.M.",
  },
  {
    image: require("./../../images/testimonials/pic2.jpg"),
    text: "John’s Vata nut roast was one of the most delicious meals I’ve had in a long, long time.",
    name: "P.S.",
  },
];

class owltestimonial extends Component {
  render() {
    var settings = {
      slidesToShow: 3,
      arrows: false,
      infinite: true,
      autoplay: true,
      responsive: [
        {
          breakpoint: 1200,
          settings: {
            slidesToShow: 3,
          },
        },
        {
          breakpoint: 991,
          settings: {
            slidesToShow: 2,
          },
        },
        {
          breakpoint: 576,
          settings: {
            slidesToShow: 1,
          },
        },
      ],
    };
    return (
      <>
        <Slider
          className="blog-carousel-center owl-carousel owl-none "
          {...settings}
        >
          {postBlog.map((item, index) => (
            <div className="item p-3" key={index}>
              <div className="testimonial-5">
                <div className="testimonial-text">
                  <p className="font">{item.text}</p>
                </div>
                <div className="testimonial-detail clearfix">
                  <div className="testimonial-pic radius shadow">
                    <img src={item.image} width="100" height="100" alt="" />
                  </div>
                  <strong className="testimonial-name font">{item.name}</strong>
                </div>
              </div>
            </div>
          ))}
        </Slider>
      </>
    );
  }
}

export default owltestimonial;
