import React from "react";

const Frame = () => {
  return (
    <>
      <div className="contanier-fluid">
        <div className="row">
          <div className="col-md-12">
            <div id="wrap">
              <iframe
                id="scaled-frame"
                src="https://weightloss.developer-ha.xyz/Plans"
              ></iframe>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Frame;
