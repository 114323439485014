import React, { useState } from "react";
import { Link } from "react-router-dom";
import { connect, useDispatch } from "react-redux";
import {
	loadingToggleAction,
	ProsignupAction,
} from "../../store/actions/AuthActions";
import Header from '../Layout/Header';
import Footer from '../Layout/Footer';
import PageTitle from '../Layout/PageTitle';


var bnr = require('./../../images/banner/bnr2.jpg');

function ProfessionalSignup(props) {
	const [email, setEmail] = useState("");
	const [role, setRole] = useState("Professional");
	let errorsObj = { email: "", password: "", password_confirmation: "", role: "", phone_number: "", address: "", user_date: "", business_type: "" };
	const [errors, setErrors] = useState(errorsObj);
	const [password, setPassword] = useState("");
	const [password_confirmation, setpassword_confirmation] = useState("");
	const [address, setAddress] = useState("");
	const [phone_number, setPhonenumber] = useState("");
	const [business_type, setBusiness] = useState("");
	const [user_date, setDate] = useState("");
	console.log(user_date)
	const dispatch = useDispatch();

	function onSignUp(e) {
		e.preventDefault();
		let error = false;
		const errorObj = { ...errorsObj };
		if (email === "") {
			errorObj.email = "Email is Required";
			error = true;
		}
		if (password === "") {
			errorObj.password = "Password is Required";
			error = true;
		}
		if (password_confirmation !== password) {

			errorObj.password_confirmation =
				"The password confirmation does not match.";
			error = true;
		}
		if (role === "") {
			errorObj.role = "Please select your Profession";
			error = true;
		}
		if (address === "") {
			errorObj.address = "Required";
			error = true;
		}
		if (phone_number === "") {
			errorObj.phone_number = "Required";
			error = true;
		}
		else {
			var mobPattern = /^\d{10}$/;
			if (!mobPattern.test(phone_number)) {
				error = true;
				errorObj.phone_number = "Invalid phone number. Must be 10 Characters";
			}
		}
		if (business_type === "") {
			errorObj.business_type = "Please Enter Your Business";
			error = true;
		}
		if (user_date === "") {
			errorObj.date = "Please Enter Your date";
			error = true;
		}
		setErrors(errorObj);
		if (error) return;
		dispatch(loadingToggleAction(true));
		dispatch(
			ProsignupAction(email, password, password_confirmation, role, address, business_type, phone_number, user_date, props.history)
		);
	}
	return (
		<>
			<Header />
			<div className="page-content">
				<div className="dez-bnr-inr overlay-black-middle bg-pt" style={{ backgroundImage: `url(${bnr})` }}>
					<PageTitle motherName="Login" activeName="Register" />
				</div>
				<div className="section-full content-inner browse-job shop-account">
					<div className="container">
						<div className="row">
							<div className="col-md-12 m-b30">
								<div className="p-a30 job-bx max-w500 radius-sm bg-white m-auto">
									<div className="tab-content">
										<form id="login" className="tab-pane active" onSubmit={onSignUp}>
											<h4 className="font-weight-700 m-b5">PERSONAL INFORMATION</h4>
											<p className="font-weight-600">If you have an account with us, please log in.</p>
											<div className="form-group">
												<input
													value={email}
													onChange={(e) => setEmail(e.target.value)}
													type="email"
													className="form-control"
													placeholder="hello@example.com"
												/>
												<div className="text-danger">
													{errors.email && <div>{errors.email}</div>}
												</div>
											</div>
											<div className="row">
												<div className="col-md-6">
													<div className="form-group">
														<input
															value={password}
															type="password"
															className="form-control"
															placeholder="Enter Password"
															defaultValue="Password"
															onChange={(e) => setPassword(e.target.value)}
														/>
														<div className="text-danger">
															{errors.password && <div>{errors.password}</div>}
														</div>
													</div>
												</div>
												<div className="col-md-6">
													<div className="form-group">
														<input
															value={password_confirmation}
															type="password"
															className="form-control"
															placeholder="Enter Password"
															defaultValue="password_confirmation"
															onChange={(e) =>
																setpassword_confirmation(e.target.value)
															}
														/>
														<div className="text-danger">
															{errors.password_confirmation && (
																<div>{errors.password_confirmation}</div>
															)}
														</div>
													</div>
												</div>
												<div className="col-md-6">
													<div className="form-group">
														<input
															type="number"
															className="form-control"
															placeholder="Phone Number"
															defaultValue="phonenumber"
															onChange={(e) => setPhonenumber(e.target.value)}
														/>
														<div className="text-danger">
															{errors.phone_number && <div>{errors.phone_number}</div>}
														</div>
													</div>
												</div>
												<div className="col-md-6">
													<div className="form-group">
														<input
															value="Professional"
															className="form-control"
															placeholder="Professional"
														/>
														<div className="text-danger">
															{errors.role && (
																<div>{errors.role}</div>
															)}
														</div>
													</div>
												</div>
												<div className="col-md-12">
													<div className="form-group">
														<input
															value={address}
															type="text"
															className="form-control"
															placeholder="Address"
															onChange={(e) => setAddress(e.target.value)}
														/>
														<div className="text-danger">
															{errors.address && <div>{errors.address}</div>}
														</div>
													</div>
												</div>
												<div className="col-md-6">
													<div className="form-group">
														<input
															type="date"
															className="form-control"
															placeholder="Date"
															onChange={(e) =>
																setDate(e.target.value)
															}
														/>
														<div className="text-danger">
															{errors.date && (
																<div>{errors.date}</div>
															)}
														</div>
													</div>
												</div>
												<div className="col-md-6">
													<div className="form-group">
														<input
															type="text"
															className="form-control"
															placeholder="Name Of Business"
															onChange={(e) =>
																setBusiness(e.target.value)
															}
														/>
														<div className="text-danger">
															{errors.business_type && (
																<div>{errors.business_type}</div>
															)}
														</div>
													</div>
												</div>
											</div>

											<div className="form-group">
												<button
													type="submit"
													className="site-button btn-block dz-xs-flex m-r5"
												>
													Sign up
												</button>
											</div>
										</form>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<Footer />
		</>
	)
}
export default ProfessionalSignup;