import React from "react";
import { useState } from "react";
import { Prev } from "react-bootstrap/esm/PageItem";
import { Link } from "react-router-dom";
// import swal from "sweetalert";
import Swal from "sweetalert2";
import Footer from "../Layout/Footer";
import Header from "../Layout/Header";

export const AdvisorQA = () => {
    const questions = [
        {
            id: 1,
            question:
                "Which word most accurately describes your vision of retirement?",
            options: ["Enjoyment", "Freedom", "Leisure", "Other"],
        },
        {
            id: 2,
            question:
                "Where do you expect the majority of your retirement income to come from?",
            options: [
                "Savings and investments",
                "Employer (Pensions)",
                "Government",
                "Other",
            ],
        },
        {
            id: 3,
            question: "Are you planning on relocating for retirement?",
            options: ["Yes", "No", "Not Sure"],
        },
        {
            id: 4,
            question: "How would you characterize your overall health?",
            options: ["Excellent", "Good", "Fair", "Poor"],
        },
        {
            id: 5,
            question: "How comfortable are you with investing?",
            options: [
                "Very comfortable",
                "Somewhat comfortable",
                "Not comfortable at all",
            ],
        },
        {
            id: 6,
            question:
                "How quickly would you like to improve your long-term financial strategy?",
            options: [
                "As soon as possible",
                "Within the next 3 months",
                "Within the next year",
                "No specific timeframe / I don't want to make any changes",
            ],
        },
        {
            id: 7,
            question: "Are you married?",
            options: ["No", "Yes"],
        },
        {
            id: 8,
            question: "Do you have an emergency fund?",
            options: ["Yes", "No"],
        },
        {
            id: 9,
            question: "Are you investing in any additional real estate assets?",
            options: ["No", "Yes"],
        },
        {
            id: 10,
            question: "Do you have an IRA?",
            options: ["Yes", "No"],
        },
        {
            id: 11,
            question: "Do you own your home?",
            options: ["No", "Yes"],
        },
        {
            id: 12,
            question:
                "Which of these is most important for your financial advisor to have?",
            options: [
                "Tax expertise",
                "Investment management expertise",
                "Estate planning expertise",
                " None of the above",
            ],
        },
        {
            id: 13,
            question: "Is socially responsible investing important to you?",
            options: ["Must have", "Nice to have", "Not important"],
        },
        {
            id: 14,
            question: "Do you currently have a financial advisor?",
            options: ["No", "Yes"],
        },
        {
            id: 15,
            question:
                "Do you need your financial advisor to be in your immediate area?",
            options: ["I am comfortable working by phone or video", "No"],
        },
        {
            id: 16,
            question: "Please estimate your household income.",
            options: [
                "LessThan $40,000",
                "$40,000 - $74,999",
                "$75,000 - $99,999",
                "$100, 000 - $149, 999",
                "$150, 000 - $249, 999",
                "Over $250, 000",
            ],
        },
        {
            id: 17,
            question: "Please estimate your total investable assets.",
            options: [
                "Less than $25,000",
                "$25, 000 - $99, 999",
                "$100, 000 - $249, 999",
                "$250, 000 - $999, 999",
                "$1, 000, 000 - $4, 999, 999",
                "Over $5, 000, 000",
            ],
        },
        {
            id: 18,
            question: "Please estimate your current monthly savings.",
            options: [
                "Less than $100",
                "Less than $100",
                "Less than $100",
                "Less than $100",
                "Less than $100",
                "Less than $100",
            ],
        },
    ];
    const [questname, setquestname] = useState(false);
    const [isIndex, setIsIndex] = useState(0);
    const [radioval, setRadioval] = useState({
        quest0: "",
        quest1: "",
        quest2: "",
        quest3: "",
        quest4: "",
        quest5: "",
        quest6: "",
        quest7: "",
        quest8: "",
        quest9: "",
        quest10: "",
        quest11: "",
        quest12: "",
        quest13: "",
        quest14: "",
        quest16: "",
        quest17: "",
        quest18: "",
        quest19: "",
        quest20: "",
    });
    const CheckValue = (e, i) => {
        setRadioval({ ...radioval, [e.target.name]: e.target.value });
    };
    const getquest = (index) => {
        return `quest${index}`;
    }
    const isChecked = (index, quest, qa) => {
        const questname = `quest${index}`;
        return quest === radioval[questname];
    };

    console.log(radioval, questname);
    const submit = () => {
        Swal.fire({
            icon: "success",
            // title: "Good job!",
            text: "We'll contact you with more information within 2 business days",
        });
    };
    const next = () => {
        setIsIndex(isIndex + 1);
    };
    const back = () => {
        setIsIndex(isIndex - 1);
    };

    return (
        <>
            <div className="page-wraper">
                <Header />
                <div className="page-content">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-1" />
                            <div className="col-md-10 border bg-white rounded-1rem mt-3">
                                <div className="mt-5">
                                    {questions.map((qa, index) => {
                                        return (

                                            <>
                                                {index === isIndex ? (
                                                    <>
                                                        <h4 className="text-center">
                                                            {" "}
                                                            <span>{qa?.id}.</span>
                                                            {qa.question}
                                                        </h4>
                                                        <div className="row ">
                                                            <div className="col-md-1"></div>
                                                            <div className="col-md-10">
                                                                {qa.options.map((quest) => (
                                                                    <div className={`acod-content border border-radius w-100 ${radioval[getquest(index)] === quest ? "isActive" : null}`}>
                                                                        <div className="radio-control mt-3 ">
                                                                            <input
                                                                                required
                                                                                id="Yellow"
                                                                                type="radio"
                                                                                name={"quest" + `${index}`}
                                                                                value={quest}
                                                                                onChange={(e) => CheckValue(e, index)}
                                                                                checked={isChecked(index, quest, qa)}
                                                                            />
                                                                            &nbsp;
                                                                            <label className="" style={{ fontSize: 20 }}>
                                                                                <p>{quest}</p>
                                                                            </label>
                                                                        </div>
                                                                    </div>
                                                                ))}
                                                            </div>
                                                            <div className="col-md-1"></div>
                                                        </div>
                                                        {isIndex === 17 ? (

                                                            <Link
                                                                to="/"
                                                                type="button"
                                                                onClick={submit}
                                                                className="site-button float-right my-3 "
                                                            >
                                                                Submit
                                                            </Link>

                                                        ) : (
                                                            <button
                                                                type="button"
                                                                className="site-button float-right my-3"
                                                                onClick={next}
                                                            >
                                                                Next
                                                            </button>
                                                        )}

                                                        {isIndex === 0 ? null : (
                                                            <button
                                                                type="button"
                                                                className="site-button float-left my-3"
                                                                onClick={back}
                                                            >
                                                                Back
                                                            </button>
                                                        )}
                                                    </>
                                                ) : null}
                                            </>
                                        );
                                    })}
                                    <div className="row"></div>
                                </div>
                            </div>
                            <div className="col-md-1" />
                        </div>
                    </div>
                </div>
                <Footer />
            </div>
        </>
    );
};
