import React from "react";
import { Link } from "react-router-dom";
import Header from "./../Layout/Header";
import Footer from "./../Layout/Footer";
import Latestblogowl from "./../Element/Owlblog2";
import Owltestimonial from "./../Element/Owlblog1";

var bnr1 = require("./../../images/banner/bnr1.jpg");
var bnr2 = require("./../../images/background/bg4.jpg");
function Aboutus() {
  var john = require("../../images/john.jpg");
  var cover = require("../../images/cover.jpg");
  return (
    <div className="page-wraper">
      <Header />
      <div className="page-content bg-white">
        <div className="content-block">
          <div className="section-full content-inner overlay-white-middle">
            <div className="container-fluid" style={{ maxWidth: "95%" }}>
              <div className="row align-items-center m-b50">
                <div className="col-md-12 col-lg-6 m-b20">
                  <h2 className="m-b5 font">
                    <b>Who Am I?</b>
                  </h2>
                  <br />
                  <br />
                  <h5 className="fw4 font">
                    John has been inspired to create with organic snack food
                    products, and more recently with this cookbook. This work
                    has shown that the simplest way to help people be healthier
                    and happier is through giving them the power to make healthy
                    meals and snacks at home.
                  </h5>
                </div>
                <div className="col-md-12 col-lg-6">
                  <img
                    src={john}
                    alt=""
                    style={{
                      width: "100%",
                      borderRadius: "100% 100% 100% 100%",
                      boxShadow: "0px 0px 30px 0px rgb(0 0 0 / 18%)",
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="container-fluid">
            <div
              style={{
                backgroundColor: "#239da6",
                opacity: 0.9,
                transition:
                  " background 0.3s, border-radius 0.3s, opacity 0.3s ",
              }}
            >
              <br />
              <br />
              <Owltestimonial />
            </div>
          </div>
          <div className="section-full content-inner overlay-white-middle">
            <div className="container-fluid " style={{ maxWidth: "95%" }}>
              <div className="row align-items-center m-b50">
                <div className="col-md-12 col-lg-6 m-b20">
                  <h2 className="m-b5 font " style={{ color: "#62bd5e" }}>
                    <b>About the book</b>
                  </h2>
                  <br />
                  <br />
                  <h5 className="fw4 font">
                    John has been inspired to create with organic snack food
                    products, and more recently with this cookbook. This work
                    has shown that the simplest way to help people be healthier
                    and happier is through giving them the power to make healthy
                    meals and snacks at home.
                  </h5>
                </div>
                <div className="col-md-12 col-lg-6 text-center">
                  <img
                    src={cover}
                    alt=""
                    style={{
                      boxShadow: "0px 0px 30px 0px rgb(0 0 0 / 18%)",
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}
export default Aboutus;
