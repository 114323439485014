import React, { useState } from "react";
import { connect, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import {
	loadingToggleAction,
	prologinAction,
} from "../../store/actions/AuthActions";
import Header from '../Layout/Header';
import Footer from '../Layout/Footer';
import PageTitle from '../Layout/PageTitle';

var bnr = require('./../../images/banner/bnr2.jpg');

function Professional_login(props) {
	const [email, setEmail] = useState("Professional@gmail.com");
	let errorsObj = { email: "", password: "" };
	const [errors, setErrors] = useState(errorsObj);
	const [password, setPassword] = useState("12345678");

	const dispatch = useDispatch();

	function onLogin(e) {
		e.preventDefault();
		let error = false;
		const errorObj = { ...errorsObj };
		if (email === "") {
			errorObj.email = "Email is Required";
			error = true;
		}
		if (password === "") {
			errorObj.password = "Password is Required";
			error = true;
		}
		setErrors(errorObj);
		if (error) {
			return;
		}
		dispatch(loadingToggleAction(true));
		dispatch(prologinAction(email, password, props.history));
	}
	return (
		<>
			<Header />
			<div className="page-content">
				<div className="dez-bnr-inr overlay-black-middle bg-pt" style={{ backgroundImage: `url(${bnr})` }}>
					<PageTitle motherName="Home" activeName="Professinal Login" />
				</div>
				<div className="section-full content-inner-2 shop-account">
					<div className="container">
						<div className="max-w500 m-auto bg-white m-b30">
							<div className="p-a30 job-bx browse-job radius-sm seth">
								<div className="tab-content nav">
									<form id="login" className="tab-pane active col-12 p-a0 " onSubmit={onLogin}>
										<h4 className="font-weight-700">LOGIN</h4>
										<p className="font-weight-600">If you have an account with us, please log in.</p>
										<div className="form-group">
											<label className="font-weight-700">E-MAIL *</label>
											<input name="dzName" required="" className="form-control" placeholder="Your Email Address" type="email" />
										</div>
										<div className="form-group">
											<label className="font-weight-700">PASSWORD *</label>
											<input name="dzName" required="" className="form-control " placeholder="Type Password" type="password" />
										</div>
										<div className="text-left">
											<button className="site-button m-r5 button-lg">login</button>
											<Link to={"/Professional-Signup"} className="m-l5 m-t15 forget-pass float-right"><i className="fa fa-unlock-alt"></i> SignUp</Link>
										</div>
									</form>

								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<Footer />
		</>
	)
}

export default Professional_login;