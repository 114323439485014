import axios from "axios";
import React, { useEffect, useState } from "react";
import Footer from "./Footer";
import Header from "./Header";

const Privacy = () => {
  const [content, setContent] = useState();
  const PrivacyData = () => {
    var config = {
      method: "get",
      url: `https://admin.the30dayweightloss.co.uk/api/user/policy`,
    };

    axios(config)
      .then(function (response) {
        setContent(response?.data?.data);
        console.log(content);
        console.log(response?.data?.data, "checking");
        if (response?.data?.status === true) {
        } else {
        }
      })
      .catch((errors) => {
        console.log(errors, "=========>errors");
      });
  };

  useEffect(() => {
    PrivacyData();
  }, []);
  return (
    <>
      <Header />
      <div className="container-fluid" style={{ maxWidth: "90%" }}>
        <div className="row ">
          <div className="my-5">
            <h3 className="font">Privacy Policy</h3>
            <br />
            <h4 className="my-3 font">Who we are</h4>
            <div dangerouslySetInnerHTML={{ __html: content?.content_one }} />
            {/* <p className="font">
              Our website address is:&nbsp;
              <a
                href="https://the30dayweightloss.com"
                style={{ color: "blue" }}
              >
                https://the30dayweightloss.com .
              </a>
            </p> */}
            <br />
            <h4 className="my-3 font">Comments</h4>
            <div dangerouslySetInnerHTML={{ __html: content?.content_two }} />
            <br />
            {/* <p className="font">
              When visitors leave comments on the site we collect the data shown
              in the comments form, and also the visitor’s IP address and
              browser user agent string to help spam detection.
              <br />
              <br />
              <br />
              An anonymized string created from your email address (also called
              a hash) may be provided to the Gravatar service to see if you are
              using it. The Gravatar service privacy policy is available here:
              <a
                href=" https://automattic.com/privacy/"
                style={{ color: "blue" }}
              >
                https://automattic.com/privacy.
              </a>
              After approval of your comment, your profile picture is visible to
              the public in the context of your comment.
            </p> */}
            <h4 className="my-3 font">Media</h4>
            <div dangerouslySetInnerHTML={{ __html: content?.content_three }} />
            <br />
            {/* <p className="font">
              If you upload images to the website, you should avoid uploading
              images with embedded location data (EXIF GPS) included. Visitors
              to the website can download and extract any location data from
              images on the website.
              <br />
              <br />
              <br />
            </p> */}
            <h4 className="my-3 font">Cookies</h4>
            <div dangerouslySetInnerHTML={{ __html: content?.content_four }} />
            <br />
            {/* <p className="font">
              If you leave a comment on our site you may opt-in to saving your
              name, email address and website in cookies. These are for your
              convenience so that you do not have to fill in your details again
              when you leave another comment. These cookies will last for one
              year.
              <br />
              <br />
              If you visit our login page, we will set a temporary cookie to
              determine if your browser accepts cookies. This cookie contains no
              personal data and is discarded when you close your browser.
              <br />
              <br />
              When you log in, we will also set up several cookies to save your
              login information and your screen display choices. Login cookies
              last for two days, and screen options cookies last for a year. If
              you select “Remember Me”, your login will persist for two weeks.
              If you log out of your account, the login cookies will be removed.
              <br />
              <br />
              If you edit or publish an article, an additional cookie will be
              saved in your browser. This cookie includes no personal data and
              simply indicates the post ID of the article you just edited. It
              expires after 1 day.
            </p> */}
            <h4 className="my-3 font">Embedded content from other websites</h4>
            <div dangerouslySetInnerHTML={{ __html: content?.content_five }} />
            <br />
            {/* <p className="font">
              Articles on this site may include embedded content (e.g. videos,
              images, articles, etc.). Embedded content from other websites
              behaves in the exact same way as if the visitor has visited the
              other website.
              <br />
              <br />
              These websites may collect data about you, use cookies, embed
              additional third-party tracking, and monitor your interaction with
              that embedded content, including tracking your interaction with
              the embedded content if you have an account and are logged in to
              that website.
            </p> */}
            <br />
            <br />
            <h4 className="my-3 font">Who we share your data with</h4>
            <div dangerouslySetInnerHTML={{ __html: content?.content_six }} />
            <br />
            {/* <p className="font">
              If you request a password reset, your IP address will be included
              in the reset email.
            </p> */}

            <h4 className="my-3 font">How long we retain your data</h4>
            <div dangerouslySetInnerHTML={{ __html: content?.content_seven }} />
            <br />
            {/* <p className="font">
              If you leave a comment, the comment and its metadata are retained
              indefinitely. This is so we can recognize and approve any
              follow-up comments automatically instead of holding them in a
              moderation queue.
              <br />
              <br />
              For users that register on our website (if any), we also store the
              personal information they provide in their user profile. All users
              can see, edit, or delete their personal information at any time
              (except they cannot change their username). Website administrators
              can also see and edit that information.
            </p> */}

            <h4 className="my-3 font">What rights you have over your data</h4>
            <div dangerouslySetInnerHTML={{ __html: content?.content_eight }} />
            <br />
            {/* <p className="font">
              If you have an account on this site, or have left comments, you
              can request to receive an exported file of the personal data we
              hold about you, including any data you have provided to us. You
              can also request that we erase any personal data we hold about
              you. This does not include any data we are obliged to keep for
              administrative, legal, or security purposes.
            </p> */}

            <h4 className="my-3 font">Where your data is sent</h4>
            <div dangerouslySetInnerHTML={{ __html: content?.content_nine }} />
            <br />
            {/* <p className="font">
              Visitor comments may be checked through an automated spam
              detection service.
            </p> */}
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Privacy;
