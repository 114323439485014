import React from "react";
import { useEffect } from "react";
import "../../css/videos.css";

const Videoone = (props) => {
  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://fast.wistia.com/embed/medias/lnj6kmtbqu.jsonp";
    script.async = true;

    const wraper1 = document.getElementsByClassName("wistia_async");
    script.after(wraper1);
  }, []);

  return (
    <>
      <span
        className="wistia_embed wistia_async_lnj6kmtbqu popover=true popoverAnimateThumbnail=true"
        style={{
          display: "inline-block",
          height: 380,
          position: "relative",
          width: "100%",
        }}
      >
        &nbsp;
      </span>
    </>
  );
};

export default Videoone;
