import React from "react";
import { useEffect } from "react";
import Videofive from "./Videofive";
import Videofour from "./Videofour";
import Videoone from "./Videoone";
import Videothree from "./Videothree";
import Videotwo from "./Videotwo";
import Header from "./../Layout/Header";
import Footer from "../Layout/Footer";
import { Link, useHistory } from "react-router-dom";
import { useState } from "react";
import "../../css/videos.css";
import download from "../../images/pdfimg.png";
import Swal from "sweetalert2";
import axios from "axios";

const Package = () => {
  const [first, setFirst] = useState(true);
  const [sec, setSec] = useState(false);
  const [thr, setThr] = useState(false);
  const [fori, setFori] = useState(false);
  const [fiv, setFiv] = useState(false);

  const one = () => {
    setFirst(true);
    setSec(false);
    setThr(false);
    setFori(false);
    setFiv(false);
  };
  const two = () => {
    setFirst(false);
    setSec(true);
    setThr(false);
    setFori(false);
    setFiv(false);
  };
  const three = () => {
    setFirst(false);
    setSec(false);
    setThr(true);
    setFori(false);
    setFiv(false);
  };
  const four = () => {
    setFirst(false);
    setSec(false);
    setThr(false);
    setFori(true);
    setFiv(false);
  };
  const five = () => {
    setFirst(false);
    setSec(false);
    setThr(false);
    setFori(false);
    setFiv(true);
  };

  const [watch, setWatch] = useState();
  const [loader, setLoader] = useState();
  const history = useHistory();
  let store2 = localStorage.getItem("userDetails");
  store2 = JSON.parse(store2);
  const WatchModal = () => {
    const Form = new FormData();
    Form.append("user_id", store2?.data?.id);

    let token = localStorage.getItem("userDetails");
    token = JSON.parse(token);
    var config = {
      method: "post",
      url: `https://admin.the30dayweightloss.co.uk/api/user/watched/${store2?.data?.id}`,
      data: Form,
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${token.token}`,
      },
    };
    axios(config)
      .then(function (response) {
        console.log(response?.data?.data?.watched);
      })
      .catch((errors) => {
        setLoader(false);
        Swal.fire({
          text: errors,
          icon: "error",
          confirmButtonText: "Logout",
          confirmButtonColor: "red",
        }).then((result) => {
          if (result.isConfirmed) {
            localStorage.clear();
            setTimeout(() => {
              history.push("/login");
              window.location.reload();
            }, 1000);
          }
        });
      });
  };
  console.log(watch, "checkingWatch");
  const Profile = () => {
    let token = localStorage.getItem("userDetails");
    token = JSON.parse(token);
    var config = {
      method: "get",
      url: `https://admin.the30dayweightloss.co.uk/api/user/view/profile/${store2?.data?.id}`,
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${token.token}`,
      },
    };

    axios(config)
      .then(function (response) {
        console.log(response?.data?.data, "get");
        setWatch(response?.data?.data?.watched);
      })
      .catch((errors) => {
        setLoader(false);
        Swal.fire({
          // title: "Oppss!",
          text: errors,
          icon: "error",
          confirmButtonText: "Logout",
          confirmButtonColor: "red",
        }).then((result) => {
          if (result.isConfirmed) {
            localStorage.clear();
            setTimeout(() => {
              history.push("/login");
              window.location.reload();
            }, 1000);
          }
        });
        console.log(errors, "=========>errors");
      });
  };
  useEffect(() => {
    Profile();
  }, []);
  setTimeout(() => {
    if (watch === "Unaccepted") {
      Swal.fire({
        html: "<h5>These five video guide you through the program so that you enjoy the 30 days with great foods and lose weight. As you will have seen the health of the gut is vital to restore a healthy weight and this is achieved through diet. Support is available from raising a ticket or requesting a call back & daily record keeping with enable you to see the results for energy, clarity of mind & happiness</h5>",
        icon: "info",
        button: "Ok",
        confirmButtonText: "OK",
      }).then((result) => {
        if (result.isConfirmed) {
          WatchModal();
        }
      });
    } else {
    }
  }, [Profile()]);
  return (
    <>
      <Header />

      <div className="container-fluid ">
        <br />
        <br />
        <h1 className="font my-5 text-center">The Program Videos </h1>
        <div className="row my-5">
          <div className="col-md-6">
            <div className="my-5 ">
              {first ? <Videoone /> : null}
              {sec ? <Videotwo /> : null}
              {thr ? <Videothree /> : null}
              {fori ? <Videofour /> : null}
              {fiv ? <Videofive /> : null}
            </div>
          </div>
          <div className="col-md-6 ">
            <div className="my-5 scrolles" style={{}}>
              <br />
              <Link to="#" onClick={() => one()}>
                <h5 className={first ? "site-button" : null}>
                  1: Program Overview
                </h5>
                <hr />
              </Link>
              <Link to="#" onClick={() => two()}>
                <h5 className={sec ? "site-button" : null}>
                  2: Ayurveda & The Mind Body Type Questionnaire
                </h5>
                <hr />
              </Link>
              <Link to="#" onClick={() => three()}>
                <h5 className={thr ? "site-button" : null}>
                  3: Daily Routine, Ghee & The Three Spice Mixes
                </h5>
                <hr />
              </Link>
              <Link to="#" onClick={() => four()}>
                <h5 className={fori ? "site-button" : null}>
                  4: Routine, Six Meal Plans & 8 Frequently Used Ingredients
                </h5>
                <hr />
              </Link>
              <Link to="#" onClick={() => five()}>
                <h5 className={fiv ? "site-button" : null}>
                  5: Details of the Meal Plans & Daily Record Keeping
                </h5>
                <hr />
              </Link>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Package;
