import React, { useState } from "react";
import { Link } from "react-router-dom";
import { connect, useDispatch } from "react-redux";
import {
  loadingToggleAction,
  signupAction,
} from "../../store/actions/AuthActions";
import { FaMinus } from "react-icons/fa";
var bnr = require("./../../images/cropped.jpeg");

function Register2(props) {
  const [email, setEmail] = useState("");
  let errorsObj = {
    email: "",
    password: "",
    password_confirmation: "",
    last_name: "",
    first_name: "",
  };
  const [errors, setErrors] = useState(errorsObj);
  const [password, setPassword] = useState("");
  const [password_confirmation, setpassword_confirmation] = useState("");
  const [first_name, setfirst_name] = useState("");
  const [last_name, setLast_name] = useState("");
  const [Loader, setLoader] = useState(false);

  const dispatch = useDispatch();

  function onSignUp(e) {
    e.preventDefault();
    setLoader(true);
    let error = false;
    const errorObj = { ...errorsObj };
    if (email === "") {
      errorObj.email = "Email is Required";
      error = true;
      setLoader(false);
    }
    if (password === "") {
      errorObj.password = "Password is Required";
      error = true;
      setLoader(false);
    }
    if (password_confirmation !== password) {
      errorObj.password_confirmation =
        "The password confirmation does not match.";
      error = true;
      setLoader(false);
    }

    // if (phone_number === "") {
    //   errorObj.phone_number = "Required";
    //   error = true;
    // }
    // else {
    //   var mobPattern = /^\d{10}$/;
    //   if (!mobPattern.test(phone_number)) {
    //     error = true;
    //     errorObj.phone_number = "Invalid phone number. Must be 10 Characters";
    //   }
    // }
    if (first_name === "") {
      errorObj.first_name = "Please Enter Your Name";
      error = true;
      setLoader(false);
    }
    if (last_name === "") {
      errorObj.last_name = "Please Enter Your Last-name";
      error = true;
      setLoader(false);
    }
    setErrors(errorObj);
    if (error) return;
    dispatch(loadingToggleAction(true));
    dispatch(
      signupAction(
        email,
        password,
        password_confirmation,
        last_name,
        first_name,
        props.history
      )
    );
  }

  return (
    <>
      {props.showLoading ? <div className="loader"></div> : null}
      {console.log(props.showLoading)}
      <div div className="page-wraper">
        <div className="browse-job login-style3">
          <div className="row">
            <div className="col-md-8 bg-white z-index2 relative p-a0 content-scroll  left-bottom">
              <div
                style={{
                  backgroundImage: `url(${bnr})`,
                  height: "100vh",
                  backgroundAttachment: "fixed",
                  backgroundSize: "70% 100%",
                }}
              ></div>
            </div>

            <div className="col-md-4 bg-white z-index2 relative p-a0 content-scroll skew-section left-bottom">
              <div className="login-form style-2">
                <div className="logo-header text-center p-tb30">
                  <Link to={"./"}>
                    <img
                      src={require("./../../images/weight.png")}
                      alt=""
                      width={"100%"}
                    />
                  </Link>
                </div>
                <div className="clearfix"></div>
                <div className="dez-separator-outer ">
                  <div className="dez-separator bg-primary style-liner"></div>
                </div>
                <div className="tab-content nav tab">
                  <div id="login" className="tab-pane active ">
                    <div className="row">
                      <div className="col-md-6">
                        <h3 className="form-title ">Register</h3>
                      </div>
                      <div className="col-md-6">
                        <div className="text-center bottom">
                          <Link
                            to={"/login"}
                            className="site-button right-arrow button-sm float-right"
                          >
                            Login
                          </Link>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-6">
                        <div className="form-group">
                          <input
                            value={first_name}
                            type="text"
                            className="form-control"
                            placeholder="First Name"
                            onChange={(e) => setfirst_name(e.target.value)}
                          />
                          <div className="text-danger">
                            {errors.first_name && (
                              <div>{errors.first_name}</div>
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group">
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Last Name"
                            defaultValue="Last Name"
                            onChange={(e) => setLast_name(e.target.value)}
                          />
                          <div className="text-danger">
                            {errors.last_name && <div>{errors.last_name}</div>}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="form-group">
                      <input
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        type="email"
                        className="form-control"
                        placeholder="hello@example.com"
                      />
                      <div className="text-danger">
                        {errors.email && <div>{errors.email}</div>}
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-6">
                        <div className="form-group">
                          <input
                            value={password}
                            type="password"
                            className="form-control"
                            placeholder="Enter Password"
                            defaultValue="Password"
                            onChange={(e) => setPassword(e.target.value)}
                          />
                          <div className="text-danger">
                            {errors.password && <div>{errors.password}</div>}
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group">
                          <input
                            value={password_confirmation}
                            type="password"
                            className="form-control"
                            placeholder="Enter Password"
                            defaultValue="password_confirmation"
                            onChange={(e) =>
                              setpassword_confirmation(e.target.value)
                            }
                          />
                          <div className="text-danger">
                            {errors.password_confirmation && (
                              <div>{errors.password_confirmation}</div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="form-group">
                      <button
                        onClick={onSignUp}
                        className="site-button btn-block dz-xs-flex m-r5"
                      >
                        Sign up
                      </button>
                    </div>
                  </div>
                </div>
                <div className="bottom-footer clearfix row text-center">
                  <div className="col-lg-12 text-center">
                    <span>
                      {" "}
                      © Copyright by
                      <a
                        href="https:www.hnhtechsolutions.com/"
                        className="text-dark"
                      >
                        &nbsp;  HnH Tech Solutions{" "}
                      </a>{" "}
                      All rights reserved.
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
const mapStateToProps = (state) => {
  return {
    errorMessage: state.auth.errorMessage,
    successMessage: state.auth.successMessage,
    showLoading: state.auth.showLoading,
  };
};
export default connect(mapStateToProps)(Register2);
