import React, { useState } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import {
  loadingToggleAction,
  loginAction,
} from "../../store/actions/AuthActions";

// image
//import logo from "../../images/logo-full-white.png";
import loginbg from "./../../images/cropped.jpeg";
import logo2 from "./../../images/Site1.png";

function Login(props) {
  const [email, setEmail] = useState("");
  let errorsObj = { email: "", password: "" };
  const [errors, setErrors] = useState(errorsObj);
  const [password, setPassword] = useState("");
  const [loader, setLoader] = useState(false);
  const dispatch = useDispatch();

  function onLogin(e) {
    setLoader(false);
    e.preventDefault();
    let error = false;
    const errorObj = { ...errorsObj };
    if (email === "") {
      errorObj.email = "Email is Required";
      error = true;
      setLoader(false);
    }
    if (password === "") {
      errorObj.password = "Password is Required";
      error = true;
      setLoader(false);
    }
    setErrors(errorObj);
    if (error) {
      setLoader(false);
      return;
    }
    dispatch(loadingToggleAction(true));
    dispatch(loginAction(email, password, props.history));
    // setLoader(false)
  }

  return (
    <>
      {props.showLoading ? <div className="loader"></div> : null}
      {console.log(props.showLoading)}
      <div className="page-wraper">
        <div
          className="page-content bg-white login-style2"
          style={{
            backgroundImage: "url(" + loginbg + ")",
            backgroundSize: "100% 100%",
          }}
        >
          <div className="section-full">
            <div className="container">
              <div className="row">
                <div className="col-lg-6 col-md-6 ">
                  <div className="text-white  align-self-center">
                    {/* <div className="logo">
                    <img src={logo2} alt="" width={1000} />
                  </div> */}
                  </div>
                </div>
                <div className="col-lg-6 col-md-6">
                  <div className="login-2 submit-resume p-a30 seth">
                    <div className="nav">
                      <form onSubmit={onLogin} className="col-12 p-a0 ">
                        <p className="font-weight-600">
                          If you have an account with us, please log in.
                        </p>

                        <div className="form-group ">
                          <label>E-Mail Address*</label>
                          <div className="input-group">
                            <input
                              type="email"
                              className="form-control"
                              placeholder="Type Your Email Address"
                              value={email}
                              onChange={(e) => setEmail(e.target.value)}
                            />
                          </div>
                          <br />
                          {errors.email && (
                            <div className="text-danger fs-12">
                              {errors.email}
                            </div>
                          )}
                        </div>
                        <div className="form-group">
                          <label>Password *</label>
                          <div className="input-group">
                            <input
                              type="password"
                              className="form-control"
                              value={password}
                              placeholder="Type Your Password"
                              onChange={(e) => setPassword(e.target.value)}
                            />
                          </div>
                          <br />
                          {errors.password && (
                            <div className="text-danger fs-12">
                              {errors.password}
                            </div>
                          )}
                        </div>
                        <div className="text-center">
                          <button className="site-button float-left">
                            login
                          </button>
                          <Link
                            to="/change-password"
                            className="site-button-link forget-pass m-t15 float-right "
                          >
                            <i className="fa fa-unlock-alt"></i> Forgot Password
                          </Link>
                        </div>
                      </form>
                      <div className="col-md-12">
                        <div className="from-group text-center my-3">
                          <p className="text-center">
                            <span>
                              Create Account?
                              <Link to="/register" className="text-success ">
                                &nbsp; Sign up
                              </Link>
                            </span>
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <footer className="login-footer">
            <div className="container">
              <div className="row">
                <div className="col-lg-12 text-center">
                  <span className="float-left">
                    © Copyright by &nbsp;
                    <a href="https://www.hnhtechsolutions.com/">
                      HnH Tech Solutions{" "}
                    </a>{" "}
                  </span>
                  <span className="float-right">All rights reserved.</span>
                </div>
              </div>
            </div>
          </footer>
        </div>
      </div>
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    errorMessage: state.auth.errorMessage,
    successMessage: state.auth.successMessage,
    showLoading: state.auth.showLoading,
  };
};
export default connect(mapStateToProps)(Login);
