/* eslint-disable jsx-a11y/anchor-is-valid */
import axios from "axios";
import React, { useEffect, useState } from "react";
import Swal from "sweetalert2";
import Footer from "../Layout/Footer";
import Header from "../Layout/Header";
import "../../css/videos.css";
import { Link, useHistory } from "react-router-dom";

const Menu = () => {
  const history = useHistory();
  const [loader, setLoader] = useState();
  const [bodyPlans, setBodyPlans] = useState();
  const [messages, setMessage] = useState("");
  let store2 = localStorage.getItem("userDetails");
  store2 = JSON.parse(store2);

  const Bodyscore = () => {
    setLoader(true);
    let token = localStorage.getItem("userDetails");
    token = JSON.parse(token);
    var config = {
      method: "get",
      url: `https://admin.the30dayweightloss.co.uk/api/user/diet/plan/${store2?.data?.id}`,
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${token.token}`,
      },
    };

    axios(config)
      .then(function (response) {
        setBodyPlans(response?.data);
        setMessage(response?.data?.message);
        if (response?.data?.status === true) {
          setLoader(false);
        } else {
          setLoader(false);
          console.log(messages);
          Swal.fire({
            // title: "Oppss!",
            text: "Update Your Mind-BodyType",
            icon: "error",
            button: "Ok",
          });
        }
      })
      .catch((errors) => {
        setLoader(false);
        Swal.fire({
          // title: "Oppss!",
          text: errors,
          icon: "error",
          confirmButtonText: "Logout",
          confirmButtonColor: "red",
        }).then((result) => {
          if (result.isConfirmed) {
            localStorage.clear();
            setTimeout(() => {
              history.push("/login");
              window.location.reload();
            }, 1000);
          }
        });
        console.log(errors, "=========>errors");
      });
  };
  console.log(bodyPlans?.message, "<===Bodyplan");
  useEffect(() => {
    Bodyscore();
  }, [1]);

  return (
    <>
      {loader ? <div className="loader"></div> : null}
      <Header />
      <div className="container my-5">
        <h2 className="text-center">Weekly Plans </h2>
        <hr />
        {bodyPlans?.status === true ? (
          <div className="row">
            {bodyPlans?.vata_simple ? (
              <div className="col-md-4 mt-3">
                <div
                  class="cardes"
                  style={{
                    background: "green",
                    text: "white",
                    borderRadius: 18,
                  }}
                >
                  <div class="multi-button">
                    <a
                      href="/SimpleVata.pdf"
                      download={" Simple Weekly Plans For Vata"}
                    >
                      <button class="fa fa-download"></button>
                    </a>
                  </div>
                  <div class="container">
                    <h5
                      className=" text-center"
                      style={{ marginTop: "2.5rem" }}
                    >
                      Simple Weekly Plans For Vata
                    </h5>
                    <div className="text-center" style={{ marginTop: "2rem" }}>
                      <a
                        href={bodyPlans?.vata_simple}
                        target="_blank"
                        className="site-button "
                      >
                        View <i className="fa fa-arrow-right "></i>
                      </a>
                    </div>
                  </div>
                </div>{" "}
              </div>
            ) : null}
            {bodyPlans?.vata_varied ? (
              <div className="col-md-4 mt-3">
                <div
                  class="cardes"
                  style={{
                    background: "green",
                    text: "white",
                    borderRadius: 18,
                  }}
                >
                  <div class="multi-button">
                    <a
                      href="/VariedVata.pdf"
                      download={"Varied Weekly Plans For Vata"}
                    >
                      <button class="fa fa-download"></button>
                    </a>
                  </div>
                  <div class="container">
                    <h5
                      className=" text-center"
                      style={{ marginTop: "2.5rem" }}
                    >
                      Varied Weekly Plans For Vata
                    </h5>
                    <div className="text-center" style={{ marginTop: "2rem" }}>
                      <a
                        href={bodyPlans?.vata_varied}
                        target="_blank"
                        className="site-button "
                      >
                        View <i className="fa fa-arrow-right "></i>
                      </a>
                    </div>
                  </div>
                </div>{" "}
              </div>
            ) : null}
            {bodyPlans?.vegetarian_vata ? (
              <div className="col-md-4 mt-3">
                <div
                  class="cardes"
                  style={{
                    background: "green",
                    text: "white",
                    borderRadius: 18,
                  }}
                >
                  <div class="multi-button">
                    <a
                      href="/VegVata.pdf"
                      download={"Vegetarian Weekly Plans For Vata"}
                    >
                      <button class="fa fa-download"></button>
                    </a>
                  </div>
                  <div class="container">
                    <h5
                      className=" text-center"
                      style={{ marginTop: "2.5rem" }}
                    >
                      Vegetarian Weekly Plans For Vata
                    </h5>
                    <div className="text-center" style={{ marginTop: "2rem" }}>
                      <a
                        href={bodyPlans?.vegetarian_vata}
                        target="_blank"
                        className="site-button "
                      >
                        View <i className="fa fa-arrow-right "></i>
                      </a>
                    </div>
                  </div>
                </div>{" "}
              </div>
            ) : null}
            {bodyPlans?.pitta_simple ? (
              <div className="col-md-4 mt-3">
                <div
                  class="cardes"
                  style={{
                    background: "green",
                    text: "white",
                    borderRadius: 18,
                  }}
                >
                  <div class="multi-button">
                    <a
                      href="/SimplePitta.pdf"
                      download={"Simple Weekly Plans For Pitta"}
                    >
                      <button class="fa fa-download"></button>
                    </a>
                  </div>
                  <div class="container">
                    <h5
                      className=" text-center"
                      style={{ marginTop: "2.5rem" }}
                    >
                      Simple Weekly Plans For Pitta
                    </h5>
                    <div className="text-center" style={{ marginTop: "2rem" }}>
                      <a
                        href={bodyPlans?.pitta_simple}
                        target="_blank"
                        className="site-button "
                      >
                        View <i className="fa fa-arrow-right "></i>
                      </a>
                    </div>
                  </div>
                </div>{" "}
              </div>
            ) : null}
            {bodyPlans?.pitta_varied ? (
              <div className="col-md-4 mt-3">
                <div
                  class="cardes"
                  style={{
                    background: "green",
                    text: "white",
                    borderRadius: 18,
                  }}
                >
                  <div class="multi-button">
                    <a
                      href="/VariedPitta.pdf"
                      download={"Varied Weekly Plans For Pitta"}
                    >
                      <button class="fa fa-download"></button>
                    </a>
                  </div>
                  <div class="container">
                    <h5
                      className=" text-center"
                      style={{ marginTop: "2.5rem" }}
                    >
                      Varied Weekly Plans For Pitta
                    </h5>
                    <div className="text-center" style={{ marginTop: "2rem" }}>
                      <a
                        href={bodyPlans?.pitta_varied}
                        target="_blank"
                        className="site-button "
                      >
                        View <i className="fa fa-arrow-right "></i>
                      </a>
                    </div>
                  </div>
                </div>{" "}
              </div>
            ) : null}
            {bodyPlans?.vegetarian_pitta ? (
              <div className="col-md-4 mt-3">
                <div
                  class="cardes"
                  style={{
                    background: "green",
                    text: "white",
                    borderRadius: 18,
                  }}
                >
                  <div class="multi-button">
                    <a
                      href="/VegPitta.pdf"
                      download={"Vegetarian Weekly Plans For Pitta"}
                    >
                      <button class="fa fa-download"></button>
                    </a>
                  </div>
                  <div class="container">
                    <h5
                      className=" text-center"
                      style={{ marginTop: "2.5rem" }}
                    >
                      Vegetarian Weekly Plans For Pitta
                    </h5>
                    <div className="text-center" style={{ marginTop: "2rem" }}>
                      <a
                        href={bodyPlans?.vegetarian_pitta}
                        target="_blank"
                        className="site-button "
                      >
                        View <i className="fa fa-arrow-right "></i>
                      </a>
                    </div>
                  </div>
                </div>{" "}
              </div>
            ) : null}
            {bodyPlans?.kapha_simple ? (
              <div className="col-md-4 mt-3">
                <div
                  class="cardes"
                  style={{
                    background: "green",
                    text: "white",
                    borderRadius: 18,
                  }}
                >
                  <div class="multi-button">
                    <a
                      href="/SimpleKapha"
                      download={"Simple Weekly Plans For Kapha"}
                    >
                      <button class="fa fa-download"></button>
                    </a>
                  </div>
                  <div class="container">
                    <h5
                      className=" text-center"
                      style={{ marginTop: "2.5rem" }}
                    >
                      Simple Weekly Plans For Kapha
                    </h5>
                    <div className="text-center" style={{ marginTop: "2rem" }}>
                      <a
                        href={bodyPlans?.kapha_simple}
                        target="_blank"
                        className="site-button "
                      >
                        View <i className="fa fa-arrow-right "></i>
                      </a>
                    </div>
                  </div>
                </div>{" "}
              </div>
            ) : null}
            {bodyPlans?.kapha_varied ? (
              <div className="col-md-4 mt-3">
                <div
                  class="cardes"
                  style={{
                    background: "green",
                    text: "white",
                    borderRadius: 18,
                  }}
                >
                  <div class="multi-button">
                    <a
                      href="/VariedKapha.pdf"
                      download={"Varied Weekly Plans For aph"}
                    >
                      <button class="fa fa-download"></button>
                    </a>
                  </div>
                  <div class="container">
                    <h5
                      className=" text-center"
                      style={{ marginTop: "2.5rem" }}
                    >
                      Varied Weekly Plans For Kapha
                    </h5>
                    <div className="text-center" style={{ marginTop: "2rem" }}>
                      <a
                        href={bodyPlans?.kapha_varied}
                        target="_blank"
                        className="site-button "
                      >
                        View <i className="fa fa-arrow-right "></i>
                      </a>
                    </div>
                  </div>
                </div>{" "}
              </div>
            ) : null}
            {bodyPlans?.vegetarian_kapha ? (
              <div className="col-md-4 mt-3">
                <div
                  class="cardes"
                  style={{
                    background: "green",
                    text: "white",
                    borderRadius: 18,
                  }}
                >
                  <div class="multi-button">
                    <a
                      href="/VegKapha.pdf"
                      download={"Vegetarian Weekly Plans For Kapha"}
                    >
                      <button class="fa fa-download"></button>
                    </a>
                  </div>
                  <div class="container">
                    <h5
                      className=" text-center"
                      style={{ marginTop: "2.5rem" }}
                    >
                      Vegetarian Weekly Plans For Kapha
                    </h5>
                    <div className="text-center" style={{ marginTop: "2rem" }}>
                      <a
                        href={bodyPlans?.vegetarian_kapha}
                        target="_blank"
                        className="site-button "
                      >
                        View <i className="fa fa-arrow-right "></i>
                      </a>
                    </div>
                  </div>
                </div>{" "}
              </div>
            ) : null}
          </div>
        ) : (
          <>
            <div className="row">
              <div className="col-md-3" />{" "}
              <div className="col-md-6 mt-3">
                <div
                  class="cardes"
                  style={{
                    background: "green",
                    text: "white",
                    borderRadius: 18,
                  }}
                >
                  <div class="container">
                    <h5
                      className=" text-center text-danger"
                      style={{ marginTop: "2.5rem" }}
                    >
                      {bodyPlans?.message}
                    </h5>
                    <div className="text-center" style={{ marginTop: "2rem" }}>
                      <Link
                        to={`/profileedit/${store2?.data.id}`}
                        className="site-button "
                      >
                        Update Your Mind-BodyType{" "}
                        <i className="fa fa-arrow-right "></i>
                      </Link>
                    </div>
                  </div>
                </div>{" "}
              </div>
              <div className="col-md-3" />{" "}
            </div>
          </>
        )}
      </div>

      <Footer />
    </>
  );
};

export default Menu;
