import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import Header from "./../Layout/Header";
import Footer from "./../Layout/Footer";
import Profilesidebar from "../Element/Profilesidebar";
import Swal from "sweetalert2";
import axios from "axios";

function ResetPassword() {
  let store2 = localStorage.getItem("userDetails");
  store2 = JSON.parse(store2);
  const [enterotp, setEnterotp] = useState(false);
  const [changepassword, setChangepassword] = useState(false);

  const [loader, setLoader] = useState(false);
  const history = useHistory();
  let token = localStorage.getItem("userDetails");
  token = JSON.parse(token);
  const SendEmail = () => {
    setLoader(true);
    var config = {
      method: "post",
      url: `https://admin.the30dayweightloss.co.uk/api/user/sent/${store2?.data?.id}`,
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${token.token}`,
      },
    };
    axios(config)
      .then(function (response) {
        setLoader(false);
        if (response?.data?.status === true) {
          setLoader(false);
          Swal.fire({
            // title: "Good job!",
            text: response?.data?.message,
            icon: "success",
            button: "Ok",
          });
          setEnterotp(true);
          setChangepassword(false);
        } else {
          setLoader(false);
          Swal.fire({
           // title: "Oppss!",
            text: response?.data?.message,
            icon: "error",
          });
        }
      })
      .catch((errors) => {
        setLoader(false);
        Swal.fire({
         // title: "Oppss!",
          text: errors,
          icon: "error",
          confirmButtonText: "Logout",
          confirmButtonColor: "red",
        }).then((result) => {
          if (result.isConfirmed) {
            localStorage.clear();
            setTimeout(() => {
              localStorage.clear();
              history.push("/login");
              window.location.reload();
            }, 5000);
          }
        });
      });
  };
  const Code = new FormData();
  const [tokenes, setToken] = useState("");
  const [gettoken, setGet_Token] = useState("");
  const OtpCode = () => {
    setLoader(true);
    Code.append("token", tokenes);
    var config = {
      method: "post",
      url: `https://admin.the30dayweightloss.co.uk/api/user/changepassword/verify`,
      data: Code,
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${token.token}`,
      },
    };
    axios(config)
      .then(function (response) {
        setGet_Token(response?.data?.token);
        setLoader(false);
        if (response?.data?.status === true) {
          setEnterotp(false);
          setChangepassword(true);

          setLoader(false);
          Swal.fire({
            // title: "Good job!",
            text: response?.data?.message,
            icon: "success",
            button: "Ok",
          });
        } else {
          setLoader(false);
          Swal.fire({
           // title: "Oppss!",
            text: response?.data?.message,
            icon: "error",
          });
        }
      })
      .catch((errors) => {
        setLoader(false);
        Swal.fire({
         // title: "Oppss!",
          text: errors,
          icon: "error",
          confirmButtonText: "Logout",
          confirmButtonColor: "red",
        }).then((result) => {
          if (result.isConfirmed) {
            localStorage.clear();
            setTimeout(() => {
              localStorage.clear();
              history.push("/login");
              window.location.reload();
            }, 5000);
          }
        });
      });
  };
  const Password = new FormData();
  const [newpassword, setNewpassword] = useState("");
  const [confirmpassword, setConfirmpassword] = useState("");
  const [current_password, setCurrent_password] = useState("");
  const UpdatePassword = () => {
    setLoader(true);
    Password.append("token", gettoken);
    Password.append("current_password", current_password);
    Password.append("new_password", newpassword);
    Password.append("confirm_password", confirmpassword);
    var config = {
      method: "post",
      url: `https://admin.the30dayweightloss.co.uk/api/user/changepassword/process/${store2?.data?.id}	`,
      data: Password,
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${token.token}`,
      },
    };
    axios(config)
      .then(function (response) {
        setToken(response?.data);
        setLoader(false);
        if (response?.data?.status === true) {
          history.push("/");
          Swal.fire({
            // title: "Good job!",
            text: response?.data?.message,
            icon: "success",
            button: "Ok",
          });
        } else {
          setLoader(false);
          Swal.fire({
           // title: "Oppss!",
            text: response?.data?.message,
            icon: "error",
          });
        }
      })
      .catch((errors) => {
        setLoader(false);
        Swal.fire({
         // title: "Oppss!",
          text: errors,
          icon: "error",
          confirmButtonText: "Logout",
          confirmButtonColor: "red",
        }).then((result) => {
          if (result.isConfirmed) {
            localStorage.clear();
            setTimeout(() => {
              localStorage.clear();
              history.push("/login");
              window.location.reload();
            }, 5000);
          }
        });
      });
  };
  useEffect(() => {
    SendEmail();
  }, []);

  return (
    <>
      {loader ? <div className="loader"></div> : null}
      <Header />
      <div className="page-content bg-white">
        <div className="content-block">
          <div className="section-full bg-white browse-job p-t50 p-b20">
            <div className="container">
              <div className="row">
                {/* <Profilesidebar /> */}
                <div className="col-xl-2 col-md-2" />
                <div className="col-xl-8 col-lg-8 m-b30">
                  <div className="job-bx job-profile">
                    <div className="job-bx-title clearfix">
                      <h5 className="font-weight-700 pull-left text-uppercase">
                        Change Password
                      </h5>
                      <Link
                        to={"/profile"}
                        className="site-button right-arrow button-sm float-right"
                      >
                        Back
                      </Link>
                    </div>
                    {enterotp ? (
                      <div className="row">
                        <div className="col-lg-6">
                          <div className="form-group">
                            <label>Enter Your 4 digit Otp Code</label>
                            <input
                              type="number"
                              className="form-control"
                              onChange={(e) => setToken(e.target.value)}
                            />
                          </div>
                        </div>
                        <div className="col-lg-12 m-b10">
                          <button
                            className="site-button"
                            onClick={() => OtpCode()}
                          >
                            Change Password
                          </button>
                        </div>
                      </div>
                    ) : null}
                    {changepassword ? (
                      <div className="row">
                        <div className="col-lg-12">
                          <div className="form-group">
                            <label>Current Password</label>
                            <input
                              type="password"
                              className="form-control"
                              onChange={(e) =>
                                setCurrent_password(e.target.value)
                              }
                            />
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <div className="form-group">
                            <label>New Password </label>
                            <input
                              type="password"
                              className="form-control"
                              onChange={(e) => setNewpassword(e.target.value)}
                            />
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <div className="form-group">
                            <label>Confirm New Password</label>
                            <input
                              type="password"
                              className="form-control"
                              onChange={(e) =>
                                setConfirmpassword(e.target.value)
                              }
                            />
                          </div>
                        </div>
                        <div className="col-lg-12 m-b10">
                          <button
                            className="site-button"
                            onClick={() => UpdatePassword()}
                          >
                            Update Password
                          </button>
                        </div>
                      </div>
                    ) : null}
                  </div>
                </div>
                <div className="col-xl-2 col-md-2" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}
export default ResetPassword;
