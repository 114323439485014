import axios from "axios";
import React, { useEffect, useState } from "react";
import { Last } from "react-bootstrap/esm/PageItem";
import { useHistory } from "react-router-dom";
import StripeCheckout from "react-stripe-checkout";
import Swal from "sweetalert2";
import "../../css/stripe.css";
import Header from "../Layout/Header";
import Header2 from "../Layout/Header2";

const Stripe = () => {
  const history = useHistory();
  const [loader, setLoader] = useState(false);
  let store2 = localStorage.getItem("userDetails");
  store2 = JSON.parse(store2);
  let tokens = localStorage.getItem("userDetails");
  tokens = JSON.parse(tokens);
  console.log(tokens.token);
  const onToken = (token) => {
    if (checkamount) {
      console.log(checkamount, "checkamount");
      setLoader(true);
      console.log(token, "token");
      var myHeaders = new Headers();
      myHeaders.append("Accept", "application/json");
      myHeaders.append("Authorization", `Bearer ${tokens.token}`);

      var formdata = new FormData();
      formdata.append("amount", checkamount);
      formdata.append("source", token?.id);
      formdata.append("user_id", store2?.data?.id);
      formdata.append("email", store2?.data?.email);

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: formdata,
      };

      fetch(
        "https://admin.the30dayweightloss.co.uk/api/user/payment/store",
        requestOptions
      )
        .then((result) => {
          if (result?.status === 200) {
            setLoader(false);
            Swal.fire({
              text: "Payment Successful",
              icon: "success",
              button: "Ok",
            });
            history.push(`/profileedit/${store2?.data?.id}`);
          } else {
            Swal.fire({
              text: result?.message
                ? result?.message.map((e) => e)
                : "Your card has Insufficient funds",
              icon: "error",
              button: "Ok",
            });
            setTimeout(() => {
              localStorage.clear();
              history.push("/login");
              window.location.reload();
            }, 5000);
          }
        })
        .catch((error) => console.log("error", error));
    } else {
    }
  };
  const [checkamount, setAmountcheck] = useState("");
  const Set_Amount = () => {
    var config = {
      method: "get",
      url: `https://admin.the30dayweightloss.co.uk/api/user/pricing`,
    };

    axios(config)
      .then(function (response) {
        setAmountcheck(response?.data?.data?.amount);

        console.log(response?.data?.data?.amount, "checking");
        if (response?.data?.status === true) {
          localStorage.setItem("Checker", "0p1a2i3d4");
        } else {
          Swal.fire({
            text: "Connection Failed!!",
            icon: "error",
            button: "Ok",
          });
        }
      })
      .catch((errors) => {
        Swal.fire({
          text: errors,
          icon: "error",
          confirmButtonText: "Logout",
          confirmButtonColor: "red",
        }).then((result) => {
          if (result.isConfirmed) {
            localStorage.clear();
            setTimeout(() => {
              localStorage.clear();
              history.push("/login");
              window.location.reload();
            }, 5000);
          }
        });
        console.log(errors, "=========>errors");
      });
  };
  useEffect(() => {
    Set_Amount();
  }, []);

  return (
    <>
      {loader ? <div className="loader"></div> : null}
      <Header2 />

      <div class="demo">
        <div class="container">
          <div class="row">
            <div class="col-md-4 col-sm-6"></div>

            <div class="col-md-4 col-sm-6">
              <div class="pricingTable">
                <div class="pricingTable-header">
                  <span class="heading">
                    The 30 Day Weight Loss Program Online
                  </span>
                  <br />
                  <h6>80% of for 20 customers at only</h6>
                </div>
                <div class="pricing-plans">
                  <span class="price-value">
                    <i class="fa fa-gbp"></i>
                    <span>{checkamount}</span>
                  </span>
                </div>
                <div class="pricingContent">
                  <ul>
                    <li>
                      <i className="fa fa-check"></i> The 30 Day Weight Loss
                      Program Cookbook
                    </li>
                    <li>
                      <i className="fa fa-check"></i> The Spice Mix for Your
                      Mind-Body Type
                    </li>
                    <li>
                      <i className="fa fa-check"></i>32 oz of Ghee (clarified
                      butter)
                    </li>
                    <li>
                      <i className="fa fa-check"></i> Support – raise a tickets
                      or request a call back
                    </li>
                  </ul>
                </div>

                <div class="pricingTable-sign-up">
                  <StripeCheckout
                    name={
                      store2?.data?.first_name + " " + store2?.data?.last_name
                    }
                    token={onToken}
                    stripeKey="pk_live_51MEzOXFQ88DBFA8r1spB2MmjoigL2K2Uk10Lej9UlhrCcX93dpfmJOZhNHLLEdBtZvlarVSy32HUSg4dt1FPxbcx00qdLlwLuh"
                    amount={checkamount * 100}
                    currency="GBP"
                  >
                    <button className="btn btn-block btn-default" id="button">
                      paynow
                    </button>
                  </StripeCheckout>
                </div>
              </div>
            </div>

            <div class="col-md-4 col-sm-6"></div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Stripe;
