import React, { Suspense, useEffect } from "react";
import Markup from "./markup/Markup";
import { connect, useDispatch } from "react-redux";
import { Route, Switch, withRouter } from "react-router-dom";
import { checkAutoLogin } from "./services/AuthService";
import { isAuthenticated } from "./store/selectors/AuthSelectors";
import "./css/plugins.css";
import "./css/style.css";
import "./css/templete.css";
import "./css/skin/skin-1.css";
import "./plugins/slick/slick.min.css";
import "./plugins/slick/slick-theme.min.css";

import Login from "./markup/Pages/Loginpage2";
import SignUp from "./markup/Pages/Register2";
import Homepage from "./markup/Pages/Homepage1";
import Error404 from "./markup/Pages/Error404";
import ProfessionalSignup from "./markup/Pages/ProfessionalSignup";
import Loginpage1 from "./markup/Pages/Professional-login";
import Payment from "./markup/Pages/payment";
import Terms from "./markup/Pages/Terms";
import Aboutus from "./markup/Pages/Aboutus";
import Contact from "./markup/Pages/Contact";
import Privacy from "./markup/Layout/Privacy";
import Changepasswordpage from "./markup/Pages/Changepasswordpage";

function App(props) {
  // const dispatch = useDispatch();
  // useEffect(() => {
  //   checkAutoLogin(dispatch, props.history);
  // }, [dispatch, props.history]);

  let routes = (
    <Switch>
      <Route path="/login" component={Login} />
      <Route path="/register" component={SignUp} />
      <Route path="/" exact component={Homepage} />;
      <Route path="/Payment-New" exact component={Payment} />;
      <Route path="/Aboutus" exact component={Aboutus} />
      <Route path="/contact" exact component={Contact} />
      <Route path="/PP" exact component={Privacy} />
      <Route path="/change-password" exact component={Changepasswordpage} />
      <Route path="*" component={Login} />
    </Switch>
  );

  if (props.isAuthenticated) {
    return (
      <>
        <Suspense
          fallback={
            <div id="preloader">
              <div className="sk-three-bounce">
                <div className="sk-child sk-bounce1"></div>
                <div className="sk-child sk-bounce2"></div>
                <div className="sk-child sk-bounce3"></div>
              </div>
            </div>
          }
        >
          <Markup />
        </Suspense>
      </>
    );
  } else {
    return (
      <div className="vh-100">
        <Suspense
          fallback={
            <div id="preloader">
              <div className="sk-three-bounce">
                <div className="sk-child sk-bounce1"></div>
                <div className="sk-child sk-bounce2"></div>
                <div className="sk-child sk-bounce3"></div>
              </div>
            </div>
          }
        >
          {routes}
        </Suspense>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    isAuthenticated: isAuthenticated(state),
  };
};

export default withRouter(connect(mapStateToProps)(App));

//export default App;
