import React from 'react';
import { Link } from 'react-router-dom';
import Header from './../Layout/Header';
import Footer from './../Layout/Footer';
import SavedJobs from './../Element/SavedJobs';
import JobProfilesidebar from '../Element/Job Profilesidebar';

function Jobsavedjobs() {
	return (
		<>
			<Header />
			<div className="page-content bg-white">
				<div className="content-block">
					<div className="section-full bg-white p-t50 p-b20">
						<div className="container">
							<div className="row">
								<JobProfilesidebar />
								<div className="col-xl-9 col-lg-8 m-b30">
									<SavedJobs />
								</div>
							</div>
						</div>
					</div>

				</div>
			</div>

			<Footer />
		</>
	)

}

export default Jobsavedjobs;