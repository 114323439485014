import axios from "axios";
import React, { useEffect, useState } from "react";
import { Last } from "react-bootstrap/esm/PageItem";
import { Link, useHistory } from "react-router-dom";
import StripeCheckout from "react-stripe-checkout";
import Swal from "sweetalert2";
import "../../css/stripe.css";
import Header from "../Layout/Header";
import Header2 from "../Layout/Header2";

const Payment = () => {
  const history = useHistory();
  const [checkamount, setAmountcheck] = useState("");
  const Set_Amount = () => {
    var config = {
      method: "get",
      url: `https://admin.the30dayweightloss.co.uk/api/user/pricing`,
    };

    axios(config)
      .then(function (response) {
        setAmountcheck(response?.data?.data?.amount);
        console.log(response?.data?.data?.amount, "checking");
        if (response?.data?.status === true) {
        } else {
          Swal.fire({
            // title: "Oppss!",
            text: "Connection Failed!!",
            icon: "error",
            button: "Ok",
          });
        }
      })
      .catch((errors) => {
        Swal.fire({
          // title: "Oppss!",
          text: errors,
          icon: "error",
          confirmButtonText: "Logout",
          confirmButtonColor: "red",
        }).then((result) => {
          if (result.isConfirmed) {
            localStorage.clear();
            setTimeout(() => {
              history.push("/login");
              window.location.reload();
            }, 1000);
          }
        });
        console.log(errors, "=========>errors");
      });
  };
  useEffect(() => {
    Set_Amount();
  }, []);
  return (
    <>
      <Header />

      <div class="demo">
        <div class="container">
          <div class="row">
            <div class="col-md-4 col-sm-6"></div>

            <div class="col-md-4 col-sm-6">
              <div class="pricingTable">
                <div class="pricingTable-header">
                  <span class="heading">
                    The 30 Day Weight Loss Program Online
                  </span>
                  <br />
                  <h6>80% of for 20 customers at only</h6>
                </div>
                <div class="pricing-plans">
                  <span class="price-value">
                    <i class="fa fa-gbp "></i>
                    <span>{checkamount}</span>
                  </span>
                </div>
                <div class="pricingContent">
                  <ul>
                    <li>
                      <i className="fa fa-check"></i> The 30 Day Weight Loss
                      Program Cookbook
                    </li>
                    <li>
                      <i className="fa fa-check"></i> The Spice Mix for Your
                      Mind-Body Type
                    </li>
                    <li>
                      <i className="fa fa-check"></i>32 oz of Ghee (clarified
                      butter)
                    </li>
                    <li>
                      <i className="fa fa-check"></i> Support – raise a tickets
                      or request a call back
                    </li>
                  </ul>
                </div>

                <div class="pricingTable-sign-up">
                  <Link className="btn btn-block btn-default" to="/register">
                    Buy Now
                  </Link>
                </div>
              </div>
            </div>

            <div class="col-md-4 col-sm-6"></div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Payment;
