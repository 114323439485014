/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Link, useHistory, useParams } from "react-router-dom";
import Header from "../Layout/Header";
import Footer from "../Layout/Footer";
import Swal from "sweetalert2";
import axios from "axios";
import { TagsInput } from "react-tag-input-component";
import PhoneInput from "react-phone-number-input";
import swal from "sweetalert";
import ScrollToTop from "../Element/ScrollToTop";
import Logout from "../Layout/Logout";
import ReactDatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
// import 'react-datepicker/dist/react-datepicker-cssmodules.min.css';
// import './Datepicker.scss'
function Profileedit() {
  const [get_profile, setGet_profile] = useState("");
  const [disable, setdisable] = useState(true);
  const able = () => {
    setdisable(false);
  };
  let store2 = localStorage.getItem("userDetails");
  store2 = JSON.parse(store2);

  const history = useHistory();
  const [api, setApi] = useState("");
  const [loader, setLoader] = useState(false);
  //Get Api
  const Profile = () => {
    setLoader(true);
    let token = localStorage.getItem("userDetails");
    token = JSON.parse(token);
    var config = {
      method: "get",
      url: `https://admin.the30dayweightloss.co.uk/api/user/view/profile/${store2?.data?.id}`,
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${token.token}`,
      },
    };

    axios(config)
      .then(function (response) {
        setGet_profile(response?.data?.data);
        console.log(get_profile);
        console.log(response, "checking");
        if (response?.data?.status === true) {
          setLoader(false);
        } else {
          setLoader(false);
          Swal.fire({
            // title: "Oppss!",
            text: "Connection Failed!!",
            icon: "error",
            button: "Ok",
          });
        }
      })
      .catch((errors) => {
        setLoader(false);
        Swal.fire({
          // title: "Oppss!",
          text: errors,
          icon: "error",
          confirmButtonText: "Logout",
          confirmButtonColor: "red",
        }).then((result) => {
          if (result.isConfirmed) {
            localStorage.clear();
            setTimeout(() => {
              history.push("/login");
              window.location.reload();
            }, 1000);
          }
        });
        console.log(errors, "=========>errors");
      });
  };
  useEffect(() => {
    Profile();
  }, []);
  //Get Api
  // Getting more Values
  // Getting Values
  const Terms = () => {
    Swal.fire({
      icon: "error",
      title: "opps",
      text: "Please Read and then Agree Terms and Conditions to continue",
      button: "OK",
    });
  };
  const IsChecked = (e) => {
    const value = e.target.value;
    const check = e.target.checked;

    if (check) {
      setChecked([...checked, value]);
      setchecking(true);
    } else {
      setChecked(checked.filter((e) => e !== value));
      setchecking(false);
    }
    console.log(check, "<===Ischecked");
  };
  let errorsObj = {
    address: "",
    postalcode: "",
    age: "",
    phonenumber: "",
    // pregnant: "",
    // birth_within_months: "",
    vata: "",
    pitta: "",
    kapha: "",
    your_predominant: "",
  };
  const [errors, setErrors] = useState(errorsObj);
  console.log(errors, errorsObj, "<====errror");
  const [weight, setweight] = useState("" ? "" : get_profile?.weight);
  const [address, setAddress] = useState("" ? "" : get_profile?.address);
  const [postalcode, setPostalcode] = useState(
    "" ? "" : get_profile?.post_code
  );
  const [checking, setchecking] = useState("");
  const [checked, setChecked] = useState("");
  const [age, setage] = useState("" ? "" : get_profile?.age);
  const [existing_medication, setexisting_medication] = useState();
  const [gender, setGender] = useState("" ? "" : get_profile?.gender);
  const [allergies, setallergies] = useState("");
  const [pregnant, setpregnant] = useState("" ? "" : get_profile?.pregnant);
  const [phonenumber, setphonenumber] = useState(
    "" ? "" : get_profile?.phone_no
  );
  const [datas2, setDatas2] = useState("");
  const [picture_both_hands, setpicture_both_hands] = useState();
  const [pull_length_picture, setpull_length_picture] = useState("");
  const [pull_length_pictures, setpull_length_pictures] = useState("");
  const [the_ten_question, setthe_ten_question] = useState("Null");
  const [kg, setkg] = useState("" ? "" : get_profile?.kg);
  const [vata, setvata] = useState("");
  const [pitta, setpitta] = useState("");
  const [kapha, setkapha] = useState("");
  const [your_mind_body, setyour_mind_body] = useState();
  const [your_predominant, setyour_predominant] = useState("");
  const [img, setimg] = useState("");
  const [imgs, setimgs] = useState("");
  // Post Api
  // append to Form Data

  const onSubmit = (e) => {
    setLoader(true);
    let error = false;
    const errorObj = { ...errorsObj };
    if (address === "") {
      errorObj.address = "Required";
      error = true;
      setLoader(false);
    }
    if (weight === "") {
      errorObj.weight = "Required";
      error = true;
      setLoader(false);
    }
    if (postalcode === "") {
      errorObj.postalcode = "Required";
      error = true;
      setLoader(false);
    }

    if (phonenumber === "") {
      errorObj.phonenumber = "Required";
      error = true;
    }

    if (age === "") {
      errorObj.age = "Required";
      error = true;
      setLoader(false);
    }

    if (gender === "") {
      errorObj.gender = "Required";
      error = true;
      setLoader(false);
    }
    if (startDate === "") {
      errorObj.startDate = "Required";
      error = true;
      setLoader(false);
    }
    if (vata === "") {
      errorObj.vata = "Required";
      error = true;
      setLoader(false);
    }
    if (pitta === "") {
      errorObj.pitta = "Required";
      error = true;
      setLoader(false);
    }
    if (kapha === "") {
      errorObj.kapha = "Required";
      error = true;
      setLoader(false);
    }
    if (your_predominant === "") {
      errorObj.your_predominant = "Required";
      error = true;
      setLoader(false);
    }
    setErrors(errorObj);
    if (error) {
      setLoader(false);
      return;
    }

    const Data = new FormData();
    Data.append("first_name", store2?.data?.first_name);
    Data.append("last_name", store2?.data?.last_name);
    Data.append("email", store2?.data?.email);
    Data.append("phone_no", phonenumber ? phonenumber : get_profile?.phone_no);
    Data.append("gender", gender ? gender : get_profile?.gender);
    Data.append("weight", weight ? weight : get_profile?.weight);
    Data.append("age", age ? age : get_profile?.age);
    Data.append("existing_medication[]", existing_medication);
    Data.append("allergies[]", allergies);
    Data.append("pregnant", pregnant);
    // Data.append("birth_within_months", "Null");
    Data.append("pull_length_picture", pull_length_picture);
    Data.append("the_ten_question", the_ten_question);
    Data.append("address", address ? address : get_profile?.address);
    Data.append("post_code", postalcode ? postalcode : get_profile?.post_code);
    Data.append("agree", checking ? checking : get_profile.agree);
    Data.append("your_mind_body", vata + "/" + pitta + "/" + kapha);
    Data.append("kg", kg ? kg : get_profile?.kg ? get_profile?.kg : "lb");
    Data.append("your_predominant", your_predominant);
    Data.append("secret_predominant", staticscore);
    let img = [];
    Array.from(images).forEach((item) => {
      img.push(item);
      Data.append("picture_both_hands[]", item);
    });

    Data.append("picture_both_hands[]", img);
    let token = localStorage.getItem("userDetails");
    token = JSON.parse(token);

    var config = {
      method: "post",
      url: `https://admin.the30dayweightloss.co.uk/api/user/profile/${store2?.data?.id}`,
      data: Data,
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${token.token}`,
      },
    };

    axios(config)
      .then(function (response) {
        setApi(response?.data);
        // localStorage.setItem("userDetails")
        setLoader(false);
        if (response?.data?.status === true) {
          setLoader(false);
          Swal.fire({
            // title: "Good job!",
            text: "Profile Updated",
            icon: "success",
            button: "Ok",
          });
          history.push(`/FeedBackForm/${store2?.data?.id}`);
        } else {
          setLoader(false);
          Swal.fire({
            // title: "Oppss!",
            text: "Check Your Fields",
            icon: "error",
            button: "Ok",
          });
        }
      })
      .catch((errors) => {
        setLoader(false);
        Swal.fire({
          // title: "Oppss!",
          text: errors,
          icon: "error",
          confirmButtonText: "Logout",
          confirmButtonColor: "red",
        }).then((result) => {
          if (result.isConfirmed) {
            localStorage.clear();
            setTimeout(() => {
              history.push("/login");
              window.location.reload();
            }, 1000);
          }
        });
      });
  };
  const [images, setImages] = useState([]);

  let data = Number(vata) + Number(pitta) + Number(kapha);
  // console.log(data);
  const bodyscore = () => {
    if (data === 10 || !vata || !pitta || !kapha) {
      if (Number(vata) > Number(pitta) && Number(vata) > Number(kapha)) {
        setyour_predominant("Vata");
      } else if (
        Number(pitta) > Number(vata) &&
        Number(pitta) > Number(kapha)
      ) {
        setyour_predominant("Pitta");
      } else if (
        Number(kapha) > Number(vata) &&
        Number(kapha) > Number(pitta)
      ) {
        setyour_predominant("Kapha");
      } else if (Number(vata) === Number(pitta)) {
        setyour_predominant("Vata/Pitta");
      } else if (Number(pitta) === Number(kapha)) {
        setyour_predominant("Pitta/Kapha");
      } else if (Number(vata) === Number(kapha)) {
        setyour_predominant("Vata/Kapha");
      } else if (Number(vata) > Number(pitta) && Number(vata) > Number(kapha)) {
        setyour_predominant("Vata");
      } else if (
        Number(pitta) > Number(vata) &&
        Number(pitta) > Number(kapha)
      ) {
        setyour_predominant("Pitta");
      } else if (
        Number(kapha) > Number(pitta) &&
        Number(kapha) > Number(vata)
      ) {
        setyour_predominant("Kapha");
      }
    } else {
      setyour_predominant("");
      Swal.fire({
        // title: "Oppss!",
        text: "Total Must be 10 ",
        icon: "error",
        button: "Ok",
      });
    }
  };
  useEffect(() => {
    bodyscore();
  }, [data]);
  const [staticscore, setStaticScore] = useState("");
  const Static_Score = () => {
    if (data === 10 || !vata || !pitta || !kapha) {
      if (Number(vata) > Number(pitta) && Number(pitta) > Number(kapha)) {
        setStaticScore("Vata/Pitta");
      } else if (
        Number(vata) === 3 &&
        Number(pitta) === 3 &&
        Number(kapha) === 4
      ) {
        setStaticScore("Vata/Pitta/Kapha");
      } else if (
        Number(vata) === 3 &&
        Number(pitta) === 4 &&
        Number(kapha) === 3
      ) {
        setStaticScore("Vata/Pitta/Kapha");
      } else if (
        Number(vata) === 4 &&
        Number(pitta) === 3 &&
        Number(kapha) === 3
      ) {
        setStaticScore("Vata/Pitta/Kapha");
      } else if (
        Number(vata) === 4 &&
        Number(pitta) === 4 &&
        Number(kapha) === 2
      ) {
        setStaticScore("Vata/Pitta/Kapha");
      } else if (
        Number(vata) === 4 &&
        Number(pitta) === 2 &&
        Number(kapha) === 4
      ) {
        setStaticScore("Vata/Pitta/Kapha");
      } else if (
        Number(vata) === 2 &&
        Number(pitta) === 4 &&
        Number(kapha) === 4
      ) {
        setStaticScore("Vata/Pitta/Kapha");
      } else if (
        Number(pitta) > Number(vata) &&
        Number(kapha) > Number(pitta)
      ) {
        setStaticScore("Kapha/Pitta");
      } else if (Number(pitta) > Number(vata) && Number(vata) > Number(kapha)) {
        setStaticScore("Pitta/Vata");
      } else if (
        Number(vata) > Number(kapha) &&
        Number(kapha) > Number(pitta)
      ) {
        setStaticScore("Vata/Kapha");
      } else if (Number(vata) < Number(pitta) && Number(kapha) > Number(vata)) {
        setStaticScore("Pitta/Kapha");
      } else if (
        Number(pitta) < Number(vata) &&
        Number(kapha) > Number(pitta)
      ) {
        setStaticScore("Kapha/Vata");
      } else if (Number(vata) > Number(pitta) && Number(vata) > Number(kapha)) {
        setStaticScore("Vata");
      } else if (
        Number(pitta) > Number(vata) &&
        Number(pitta) > Number(kapha)
      ) {
        setStaticScore("Pitta");
      } else if (
        Number(kapha) > Number(pitta) &&
        Number(kapha) > Number(vata)
      ) {
        setStaticScore("Kapha");
      }
    } else {
      setStaticScore("");
      Swal.fire({
        title: "Oppss!",
        text: "Total Must be 10 ",
        icon: "error",
        button: "Ok",
      });
    }
  };
  useEffect(() => {
    Static_Score();
  }, [data]);

  console.log(get_profile?.agree);
  const [startDate, setStartDate] = useState(new Date());

  return (
    <>
      {loader ? <div className="loader"></div> : null}
      <Header />
      <div className="page-content bg-white">
        <div className="content-block">
          <div className="section-full bg-white browse-job p-t50 p-b20">
            <div className="container">
              <div className="row">
                {/* <Profilesidebar
                  /> */}
                <div className="col-xl-12 col-lg-12 m-b30">
                  <div className="job-bx job-profile">
                    <div className="job-bx-title clearfix">
                      <div className="row">
                        <div className="col-md-8">
                          <h5 className="font-weight-700  text-uppercase">
                            Personal Profile Edit
                          </h5>
                        </div>
                        <div className=" col-md-4">
                          <div className="text-center bottom">
                            <Link
                              to={`/Profile/${store2?.data.id}`}
                              className="site-button right-arrow button-sm float-right"
                            >
                              Profile
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="row m-b30">
                      <div className="col-lg-6 col-md-6">
                        <div className="form-group">
                          <label>
                            First Name:<span className="text-danger">*</span>
                          </label>
                          <input
                            disabled
                            value={store2?.data.first_name}
                            type="text"
                            className="form-control"
                          />
                        </div>
                      </div>
                      <div className="col-lg-6 col-md-6">
                        <div className="form-group">
                          <label>
                            Last Name:<span className="text-danger">*</span>
                          </label>
                          <input
                            disabled
                            value={store2?.data.last_name}
                            type="text"
                            className="form-control"
                          />
                        </div>
                      </div>
                      <div className="col-lg-12 col-md-12">
                        <div className="form-group">
                          <label>
                            Email:<span className="text-danger">*</span>
                          </label>
                          <input
                            disabled
                            value={store2?.data.email}
                            type="text"
                            className="form-control"
                          />
                        </div>
                      </div>
                      <div className="col-md-9">
                        <div className="form-group">
                          <label>
                            Address:<span className="text-danger">*</span>
                          </label>
                          <input
                            defaultValue={get_profile?.address}
                            type="text"
                            className="form-control"
                            onChange={(e) => setAddress(e.target.value)}
                          />
                          {errors.address && (
                            <span className="text-danger">
                              {errors.address}
                            </span>
                          )}
                        </div>
                      </div>
                      <div className="col-md-3">
                        <div className="form-group">
                          <label>
                            PostCode:
                            <span className="text-danger">*</span>
                          </label>
                          <input
                            defaultValue={get_profile?.post_code}
                            type="tel"
                            className="form-control"
                            onChange={(e) => setPostalcode(e.target.value)}
                          />
                          {errors.postalcode && (
                            <span className="text-danger">
                              {errors.postalcode}
                            </span>
                          )}
                        </div>
                      </div>
                      <div className="col-lg-4 col-md-4">
                        <div className="form-group">
                          <label>
                            Gender:<span className="text-danger">*</span>
                          </label>
                          <select
                            type="text"
                            className="form-control"
                            onChange={(e) => {
                              setGender(e.target.value);
                            }}
                          >
                            <option disabled selected>
                              {get_profile?.gender
                                ? get_profile?.gender
                                : "Select Your Gender"}
                            </option>
                            <option value="Male">Male</option>
                            <option value="Female">Female</option>
                            <option value="Other">Other</option>
                          </select>
                          {errors.gender && (
                            <span className="text-danger">{errors.gender}</span>
                          )}
                        </div>
                      </div>

                      <div className="col-lg-4 col-md-4">
                        <label>
                          Weight:<span className="text-danger">*</span>
                        </label>
                        <div className="row">
                          <div className="col-md-4">
                            <div className="form-group">
                              <select
                                style={{ padding: 1 }}
                                id="questions"
                                type="text"
                                className="form-control"
                                defaultValue={get_profile?.kg}
                                onChange={(e) => setkg(e.target.value)}
                              >
                                <option>kg</option>
                                {get_profile ? (
                                  <option>lb</option>
                                ) : (
                                  <option selected>lb</option>
                                )}
                                <option>st</option>
                              </select>
                            </div>
                          </div>
                          <div className="col-md-8">
                            <div className="form-group">
                              <input
                                type="number"
                                className="form-control"
                                defaultValue={get_profile?.weight}
                                onChange={(e) => {
                                  setweight(e.target.value);
                                }}
                              />
                              {errors.weight && (
                                <span className="text-danger">
                                  {errors.weight}
                                </span>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-4 col-md-4">
                        <div className="form-group">
                          <label>
                            Age:<span className="text-danger">*</span>
                          </label>
                          <input
                            defaultValue={get_profile?.age}
                            type="number"
                            className="form-control"
                            onChange={(e) => {
                              setage(e.target.value);
                            }}
                          />
                          {errors.age && (
                            <span className="text-danger">{errors.age}</span>
                          )}
                        </div>
                      </div>
                      {gender === "Male" ? null : (
                        <div className="col-lg-12 col-md-12">
                          <div className="form-group">
                            <label>
                              Pregnant:<span className="text-danger">*</span>
                            </label>
                            <select
                              className="form-control"
                              onChange={(e) => {
                                setpregnant(e.target.value);
                              }}
                            >
                              <option selected disabled>
                                {get_profile?.pregnant
                                  ? get_profile?.pregnant
                                  : "Are You Pregant?"}
                              </option>
                              <option>Yes</option>
                              <option>No</option>
                            </select>
                            {/* {errors.pregnant && (
                              <span className="text-danger">
                                {errors.pregnant}
                              </span>
                            )} */}
                          </div>
                        </div>
                      )}
                      <div className="col-lg-6 col-md-6">
                        <div className="form-group">
                          <label>Existing Medication:</label>
                          <TagsInput
                            value={existing_medication}
                            onChange={setexisting_medication}
                            placeHolder="enter Medication"
                          />
                        </div>
                      </div>
                      <div className="col-lg-6 col-md-6">
                        <div className="form-group">
                          <label>Allergies:</label>
                          <TagsInput
                            value={allergies}
                            onChange={setallergies}
                            placeHolder="enter Allergies"
                          />
                        </div>
                      </div>

                      <div className="col-lg-6 col-md-6">
                        <div className="form-group">
                          <label>
                            Phone:<span className="text-danger">*</span>
                          </label>
                          <PhoneInput
                            international
                            // countryCallingCodeEditable={false}
                            placeholder="Enter phone number"
                            onChange={setphonenumber}
                            value={get_profile?.phone_no}
                          />
                          {errors.phonenumber && (
                            <span className="text-danger">
                              {errors.phonenumber}
                            </span>
                          )}
                        </div>
                      </div>
                      {/* <div className="col-lg-6 col-md-6">
                        <div className="form-group">
                          <label>
                            Date of Birth:<span className="text-danger">*</span>
                          </label>

                          <ReactDatePicker
                            selected={startDate}
                            onChange={(date) => setStartDate(date.format("mm/dd/yyyy"))}
                            className="form-control"
                          />
                          {errors.birth_within_months && (
                            <span className="text-danger">
                              {errors.birth_within_months}
                            </span>
                          )}
                        </div>
                      </div> */}
                      {/* <div className="col-lg-6 col-md-6">
                        <div className="form-group">
                          <label>Picture Both Hands:</label>
                          <input
                            className="form-control"
                            multiple
                            onChange={(e) => setImages(e.target.files)}
                            type="file"
                          />

                          {images ? (
                            <div className="row">
                              {Array.from(images).map((item) => {
                                console.log(item, "<+=====checkingitem");
                                return (
                                  <>
                                    <div className="col-md-6">
                                      <img
                                        src={
                                          //   `https://weightlossadmin.the30dayweightloss.com/images/${profile?.picture_both_hands}`
                                          URL.createObjectURL(item)
                                        }
                                        alt=""
                                        width={"100%"}
                                        className="rounded mt-3 mb-1"
                                        style={{ height: 99 }}
                                      />
                                    </div>
                                  </>
                                );
                              })}
                            </div>
                          ) : null}
                        </div>
                      </div>
                      <div className="col-lg-6 col-md-6">
                        <div className="form-group">
                          <label>Pull Length Picture:</label>
                          <input
                            type="file"
                            className="form-control"
                            onChange={(e) => {
                              setpull_length_picture(e.target.files[0]);
                              setpull_length_pictures(
                                URL.createObjectURL(e.target.files[0])
                              );
                            }}
                          />
                          {pull_length_pictures ? (
                            <div className="col-md-12">
                              <img
                                className="rounded mt-3 mb-1"
                                src={pull_length_pictures}
                                alt=""
                                width={"100%"}
                                style={{ height: 99 }}
                              />
                            </div>
                          ) : null}
                        </div>
                      </div> */}
                      {/* <div className="col-lg-6 col-md-6">
                        <div className="form-group">
                          <label>The Ten Question:</label>
                          <select
                            id="questions"
                            type="text"
                            className="form-control"
                            onChange={(e) => {
                              setthe_ten_question(e.target.value);
                            }}
                          >
                            <option disabled selected>
                              Select your questions
                            </option>
                            <option>Vata/Pitta</option>

                            <option>Vata/Kapha</option>

                            <option>Pitta/Vata</option>

                            <option>Pitta/Kapha</option>

                            <option>Kapha/Vata</option>

                            <option>Kapha/Pitta</option>

                            <option>Vata/Pitta/Kapha</option>

                            <option>Vata</option>

                            <option>Pitta</option>

                            <option>Kapha</option>
                          </select>
                        </div>
                      </div> */}
                      <div className="col-lg-6 col-md-6">
                        <div className="form-group">
                          <label>
                            Your Mind-Body Type:
                            <span className="text-danger">*</span>
                            View the home page video if you haven't done it yet
                          </label>
                          <div className="row">
                            <div className="col-md-4">
                              <input
                                defaultValue={get_profile?.vata}
                                id="result"
                                placeholder="Vata "
                                type="number"
                                className="form-control"
                                value={vata}
                                onChange={(e) => {
                                  if (e.target.value <= 10) {
                                    setvata(e.target.value);
                                  } else {
                                    Swal.fire({
                                      // title: "Oppss!",
                                      text: "Value must be less than 10",
                                      icon: "error",
                                      button: "Ok",
                                    });
                                  }
                                }}
                              />
                              {errors.vata && (
                                <span className="text-danger">
                                  {errors.vata}
                                </span>
                              )}
                            </div>
                            <div className="col-md-4">
                              <input
                                defaultValue={get_profile?.pitta}
                                placeholder="Pitta "
                                type="number"
                                value={pitta}
                                className="form-control"
                                onChange={(e) => {
                                  if (e.target.value <= 10) {
                                    setpitta(e.target.value);
                                  } else {
                                    Swal.fire({
                                      // title: "Oppss!",
                                      text: "Value must be less than 10",
                                      icon: "error",
                                      button: "Ok",
                                    });
                                  }
                                }}
                              />
                              {errors.pitta && (
                                <span className="text-danger">
                                  {errors.pitta}
                                </span>
                              )}
                            </div>
                            <div className="col-md-4">
                              <input
                                defaultValue={get_profile?.kapha}
                                placeholder="Kapha "
                                type="number"
                                value={kapha}
                                className="form-control"
                                onChange={(e) => {
                                  if (e.target.value <= 10) {
                                    setkapha(e.target.value);
                                  } else {
                                    Swal.fire({
                                      // title: "Oppss!",
                                      text: "Value must be less than 10",
                                      icon: "error",
                                      button: "Ok",
                                    });
                                  }
                                }}
                              />
                              {errors.kapha && (
                                <span className="text-danger">
                                  {errors.kapha}
                                </span>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-6 col-md-6">
                        <div className="form-group">
                          <label>
                            Your Mind-Body Type
                            <span className="text-danger">*</span>
                          </label>
                          <input
                            disabled
                            value={staticscore}
                            type="text"
                            className="form-control"
                          />
                          {errors.your_predominant && (
                            <span className="text-danger">
                              {errors.your_predominant}
                            </span>
                          )}
                        </div>
                      </div>

                      {/* <div className="col-md-6">
                        <div className="form-group">
                          <span>
                            <input type="checkbox" onChange={IsChecked} />
                            &nbsp;&nbsp;
                            <label className="text-muted">
                              I Agree to the{" "}
                              <Link
                                to={"/Term&Conditions"}
                                target="_blank"
                                className="text-primary"
                                style={{ color: "blue" }}
                              >
                                <u>
                                  <b>Terms and conditions</b>
                                </u>
                              </Link>
                              .
                            </label>
                          </span>{" "}
                        </div>
                      </div>
                      <div className="col-md-6" />
                      <div className="col-md-4" />
                      <div className="col-md-4">
                        <div className="form-group">
                          <button
                            style={{
                              height: 47,
                              marginTop: "2.2rem",
                            }}
                            className="site-button w-100 m-b30 "
                            type="button"
                            onClick={
                              checking === true
                                ? () => onSubmit()
                                : () => Terms()
                            }
                          >
                            Save Changes
                          </button>
                        </div>
                        <div className="col-md-4" />
                      </div> */}
                    </div>
                    {get_profile?.agree === "true" ? (
                      <>
                        <div className="row">
                          <div className="col-md-6">
                            <div className="form-group">
                              <span>
                                <input type="checkbox" checked />
                                &nbsp;&nbsp;
                                <label className="text-muted">
                                  I agree to the{" "}
                                  <Link
                                    to={"/Term&Conditions"}
                                    target="_blank"
                                    className="text-primary"
                                    style={{ color: "blue" }}
                                  >
                                    <u>
                                      <b>terms and conditions</b>
                                    </u>
                                  </Link>
                                </label>
                                <br />
                                <label className="text-muted">
                                  You must click this link to be able to agree
                                  to terms and conditions.
                                </label>
                              </span>{" "}
                            </div>
                          </div>
                          <div className="col-md-6" />
                          <div className="col-md-4" />
                          <div className="col-md-4">
                            <div className="form-group">
                              <button
                                style={{
                                  height: 47,
                                  marginTop: "2.2rem",
                                }}
                                className="site-button w-100 m-b30 "
                                type="button"
                                onClick={() => onSubmit()}
                              >
                                Save Changes
                              </button>
                            </div>
                            <div className="col-md-4" />
                          </div>
                        </div>
                      </>
                    ) : (
                      <>
                        <div className="row">
                          <div className="col-md-6">
                            <div className="form-group">
                              <span>
                                <input
                                  title="First Read Term And Conditions"
                                  type="checkbox"
                                  onChange={IsChecked}
                                  disabled={disable}
                                />
                                &nbsp;&nbsp;
                                <label className="text-muted">
                                  I agree to the{" "}
                                  <Link
                                    onClick={() => able()}
                                    to={"/Term&Conditions"}
                                    target="_blank"
                                    className="text-primary"
                                    style={{ color: "blue" }}
                                  >
                                    <u>
                                      <b>terms and conditions</b>
                                    </u>
                                  </Link>
                                </label>
                                <br />
                                <label className="text-muted">
                                  You must click this link to be able to agree
                                  to terms and conditions.
                                </label>
                              </span>{" "}
                            </div>
                          </div>
                          <div className="col-md-6" />
                          <div className="col-md-4" />
                          <div className="col-md-4">
                            <div className="form-group">
                              <button
                                style={{
                                  height: 47,
                                  marginTop: "2.2rem",
                                }}
                                className="site-button w-100 m-b30 "
                                type="button"
                                onClick={checking === true ? onSubmit : Terms}
                              >
                                Save Changes
                              </button>
                            </div>
                            <div className="col-md-4" />
                          </div>
                        </div>
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}
export default Profileedit;
