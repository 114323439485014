import React from "react";
import { connect, useDispatch } from "react-redux";
import { Link, useLocation, withRouter } from "react-router-dom";

import { logout } from "../../store/actions/AuthActions";
import { isAuthenticated } from "../../store/selectors/AuthSelectors";

function LogoutPage(props) {
//   const location = useLocation();
  const dispatch = useDispatch();
//   location.reload();
  function onLogout() {
    dispatch(logout(props.history));
    // window.location.reload();
  }
  return (
    <>
      <a href="/" title="LogOut" onClick={onLogout} className={props.class}>
        <i className="fa fa-sign-out" aria-hidden="true"></i>
        <span>Logout</span>
      </a>
    </>
  );
}
const mapStateToProps = (state) => {
  return {
    isAuthenticated: isAuthenticated(state),
  };
};

export default withRouter(connect(mapStateToProps)(LogoutPage));
