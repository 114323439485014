import React from "react";
import { useEffect } from "react";

const Videothree = () => {
  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://fast.wistia.com/embed/medias/zolp4m33m9.jsonp";
    script.async = true;
    const wraper = document.getElementsByClassName("wistia_embed");
    script.after(wraper);
  }, []);
  return (
    <>
      <span
        className="wistia_embed wistia_async_zolp4m33m9 popover=true popoverAnimateThumbnail=true"
        style={{
          display: "inline-block",
          height: 380,
          position: "relative",
          width: "100%",
        }}
      >
        &nbsp;
      </span>
    </>
  );
};

export default Videothree;
