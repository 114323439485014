import React from 'react';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import Logout from '../Layout/Logout';
import Profilepic from "./../../images/team/pic1.jpg"

function JobProfilesidebar() {
	const [img, setimg] = useState("");
	const [imgs, setimgs] = useState("");
	console.log(imgs)

	return (
		<div className="col-xl-3 col-lg-4 m-b30">
			<div className="sticky-top">
				<div className="candidate-info">
					<div className="candidate-detail text-center">
						<div className="canditate-des">
							<img alt="" src={img ? img : Profilepic} />
							<div className="upload-link" title="update" data-toggle="tooltip" data-placement="right">
								<input type="file" className="update-flie"
									onChange={
										(e) => {
											setimg(URL.createObjectURL(e.target.files[0]))
											setimgs(e.target.files[0])
										}
									}
								/>
								<i className="fa fa-camera"></i>
							</div>
						</div>
						<div className="candidate-title">
							<div className="">
								<h4 className="m-b5"><Link to={"#"}>David Matin</Link></h4>
								<p className="m-b0"><Link to={"#"}>Web developer</Link></p>
							</div>
						</div>
					</div>
					<ul>
						<li><Link to={"/post-jobs"} className="active">
							<i className="fa fa-file-text-o" aria-hidden="true"></i>
							<span>Post A Job</span></Link></li>
						<li><Link to={"/saved-jobs"}>
							<i className="fa fa-heart-o" aria-hidden="true"></i>
							<span>Saved Jobs</span></Link></li>
						<li><Link to={"/manage-job"}>
							<i className="fa fa-briefcase" aria-hidden="true"></i>
							<span>Manage jobs</span></Link></li>
						<li><Link to={"/resume"}>
							<i className="fa fa-id-card-o" aria-hidden="true"></i>
							<span>Resume</span></Link></li>
						<li>
							<Logout />
						</li>
					</ul>
				</div>
			</div>
		</div>
	)
}
export default JobProfilesidebar;