/* eslint-disable no-lone-blocks */
import axios from "axios";
import React, { useEffect } from "react";
import { useState } from "react";
import { Link, useHistory } from "react-router-dom";
import Swal from "sweetalert2";
import Profilesidebar from "../Element/Profilesidebar";
import Footer from "../Layout/Footer";
import Header from "../Layout/Header";
import Logout from "../Layout/Logout";
const Feedbackform = () => {
  const scrolltop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  // Get Api
  const Getapi = () => {
    setLoader(true);
    let token = localStorage.getItem("userDetails");
    token = JSON.parse(token);
    var config = {
      method: "get",
      url: `https://admin.the30dayweightloss.co.uk/api/user/feedback/view/${store2?.data?.id}`,
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${token.token}`,
      },
    };

    axios(config)
      .then(function (response) {
        setLoader(true);
        setApi_data(response?.data?.data);
        setFirstEachDay(response?.data?.data[0]?.weight_each_day);
        // console.log(api_Data?.weight_each_day[0], "checking");
        if (response?.data?.status === true) {
          setLoader(false);
        } else {
          setLoader(false);
          Swal.fire({
            // title: "Oppss!",
            text: "Connection Failed!!",
            icon: "error",
            button: "Ok",
          });
        }
      })
      .catch((errors) => {
        setLoader(false);
        Swal.fire({
          // title: "Oppss!",
          text: errors,
          icon: "error",
          confirmButtonText: "Logout",
          confirmButtonColor: "red",
        }).then((result) => {
          if (result.isConfirmed) {
            localStorage.clear();
            setTimeout(() => {
              history.push("/login");
              window.location.reload();
            }, 1000);
          }
        });
        console.log(errors, "=========>errors");
      });
  };
  useEffect(() => {
    Getapi();
  }, []);

  // Get Api end

  let store2 = localStorage.getItem("userDetails");
  store2 = JSON.parse(store2);
  const [api, setApi] = useState();
  const [api_Data, setApi_data] = useState([]);
  const [loader, setLoader] = useState();
  let history = useHistory();
  // Getting Values
  let errorsObj = {
    kg: "",
    sugar: " ",
    gluten: "",
    dariy: "",
    corn: "",
    energy: "",
    focus: "",
    happiness: "",
    elimination: "",
    weightEachDay: "",
    firstEachDay: "",
  };
  const [errors, setErrors] = useState(errorsObj);
  const [day, setDay] = useState("");
  const [kg, setkg] = useState("");
  const [sugar, setSugar] = useState("");
  const [gluten, setGluten] = useState("");
  const [dariy, setDariy] = useState("");
  const [corn, setCorn] = useState("");
  const [energy, setEnergy] = useState("0");
  const [focus, setFocus] = useState("0");
  const [happiness, setHappiness] = useState("0");
  const addscore = Number(energy) + Number(focus) + Number(happiness);
  const [elimination, setElimination] = useState("");
  const [weightEachDay, setWeightEachDay] = useState("");
  const [firstEachDay, setFirstEachDay] = useState("0");
  const [each_calplus, setEach_calplus] = useState("0");
  const [cumulativeplus, setCumulativeplus] = useState("0");
  //   Getting Data
  const FeedBack = () => {
    setLoader(true);
    let error = false;
    const errorObj = { ...errorsObj };
    if (sugar === "") {
      errorObj.sugar = "Required";
      error = true;
      scrolltop();
      setLoader(false);
    }
    if (gluten === "") {
      errorObj.gluten = "Required";
      error = true;
      scrolltop();
      setLoader(false);
    }
    if (dariy === "") {
      errorObj.dariy = "Required";
      error = true;
      scrolltop();
      setLoader(false);
    }
    if (corn === "") {
      errorObj.corn = "Required";
      error = true;
      scrolltop();
      setLoader(false);
    }
    if (energy === "0") {
      errorObj.energy = "Required";
      error = true;
      scrolltop();
      setLoader(false);
    }
    if (focus === "0") {
      errorObj.focus = "Required";
      error = true;
      scrolltop();
      setLoader(false);
    }
    if (happiness === "") {
      errorObj.happiness = "Required";
      error = true;
      scrolltop();
      setLoader(false);
    }
    if (elimination === "") {
      errorObj.elimination = "Required";
      error = true;
      scrolltop();
      setLoader(false);
    }
    if (kg === "") {
      errorObj.kg = "Required";
      error = true;
      scrolltop();
      setLoader(false);
    }
    if (weightEachDay === "") {
      errorObj.weightEachDay = "Required";
      error = true;
      scrolltop();
      setLoader(false);
    }
    setErrors(errorObj);
    if (error) return;
    setLoader(false);
    console.log(loader);
    const Form = new FormData();
    Form.append("user_id", store2?.data?.id);
    Form.append("day", `day`);
    Form.append("refined_sugar", sugar);
    Form.append("gluten", gluten);
    Form.append("dairy", dariy);
    Form.append("corn_syrup", corn);
    Form.append("energy", energy);
    Form.append("kg", kg);
    Form.append("focus", focus);
    Form.append("happiness", happiness);
    Form.append("total_score", addscore);
    Form.append("elimination", elimination);
    Form.append("weight_each_day", weightEachDay);
    Form.append("weight_lost_per_day", each_calplus ? each_calplus : each_cal);
    Form.append(
      "cumulative_loss_or_gain",
      cumulativeplus ? cumulativeplus : firsteach_cal
    );
    let token = localStorage.getItem("userDetails");
    token = JSON.parse(token);
    var config = {
      method: "post",
      url: `https://admin.the30dayweightloss.co.uk/api/user/feedback/store`,
      data: Form,
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${token.token}`,
      },
    };
    axios(config)
      .then(function (response) {
        setApi(response?.data);
        // localStorage.setItem("userDetails")
        setLoader(false);
        if (response?.data?.status === true) {
          setLoader(false);
          history.push("/Feedback");
          // Swal.fire({
          //   // title: "Good job!",
          //   text: "Remember they fill this is every day for 30 days.",
          //   icon: "success",
          //   button: "Ok",
          // });
        } else {
          setLoader(false);
          history.push("/Feedback");
          Swal.fire({
            // title: "Oppss!",
            text: "You have submitted your daily feedback for today.",
            icon: "success",
          });
        }
      })
      .catch((errors) => {
        setLoader(false);
        Swal.fire({
          // title: "Oppss!",
          text: errors,
          icon: "error",
          confirmButtonText: "Logout",
          confirmButtonColor: "red",
        }).then((result) => {
          if (result.isConfirmed) {
            localStorage.clear();
            setTimeout(() => {
              history.push("/login");
              window.location.reload();
            }, 1000);
          }
        });
      });
  };

  let cal = api_Data?.length;
  const each_cal =
    Number(weightEachDay) - Number(api_Data[cal - 1]?.weight_each_day);
  const plus = () => {
    if (each_cal >= "0") {
      setEach_calplus("+" + each_cal);
    } else {
      setEach_calplus("");
    }
  };
  useEffect(() => {
    plus();
  }, [each_cal]);

  let firsteach_cal = Number(weightEachDay) - Number(firstEachDay);
  const pluseach_cal = () => {
    if (firsteach_cal > "0") {
      setCumulativeplus("+" + firsteach_cal);
    } else {
      setCumulativeplus("");
    }
  };
  useEffect(() => {
    pluseach_cal();
  }, [firsteach_cal]);

  return (
    <>
      {loader ? <div className="loader"></div> : null}

      <Header />
      <div className="page-content bg-white">
        <div className="content-block">
          <div className="section-full bg-white browse-job p-t50 p-b20">
            <div className="container-fluid" style={{ maxWidth: "80%" }}>
              <div className="row">
                <div className="col-xl-12 col-lg-12 m-b30">
                  <div className="job-bx job-profile">
                    <div className="job-bx-title clearfix">
                      <div className="row">
                        <div className="col-md-8">
                          <h5 className="font-weight-700 font  text-uppercase">
                            FeedBack Chart Form
                          </h5>
                        </div>
                        <div className=" col-md-4">
                          <div className="text-center bottom">
                            <Link
                              to={"/"}
                              className="site-button right-arrow button-sm float-right"
                            >
                              Back
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row m-b30">
                      {/* <div className="col-lg-3 col-md-3">
                        <div className="form-group">
                          <label>Day:</label>
                          <select
                            className="form-control"
                            onChange={(e) => setDay(e.target.value)}
                          >
                            <option selected disabled>
                              ....
                            </option>
                            {Days1.map((d) => {
                              return (
                                <>
                                  <option>{d}</option>
                                </>
                              );
                            })}
                          </select>
                        </div>
                      </div> */}
                      <div className="col-xs-3 col-lg-3 col-md-3">
                        <div className="form-group">
                          <label>Refined Sugar :</label>
                          <select
                            className="form-control"
                            onChange={(e) => setSugar(e.target.value)}
                          >
                            <option selected disabled>
                              ....
                            </option>
                            <option>Yes</option>
                            <option>No</option>
                          </select>
                          {errors.sugar && (
                            <span className="text-danger">{errors.sugar}</span>
                          )}
                        </div>
                      </div>
                      <div className="col-xs-3 col-lg-3 col-md-3">
                        <div className="form-group">
                          <label>Gluten:</label>
                          <select
                            className="form-control"
                            onChange={(e) => setGluten(e.target.value)}
                          >
                            <option selected disabled>
                              ....
                            </option>
                            <option>Yes</option>
                            <option>No</option>
                          </select>
                          {errors.gluten && (
                            <span className="text-danger">{errors.gluten}</span>
                          )}
                        </div>
                      </div>
                      <div className="col-xs-3 col-lg-3 col-md-3">
                        <div className="form-group">
                          <label>Dairy:</label>
                          <select
                            className="form-control"
                            onChange={(e) => setDariy(e.target.value)}
                          >
                            <option selected disabled>
                              ....
                            </option>
                            <option>Yes</option>
                            <option>No</option>
                          </select>
                          {errors.dariy && (
                            <span className="text-danger">{errors.dariy}</span>
                          )}
                        </div>
                      </div>

                      <div className="col-xs-3 col-lg-3 col-md-3">
                        <div className="form-group">
                          <label>Corn Syrup:</label>
                          <select
                            className="form-control"
                            onChange={(e) => setCorn(e.target.value)}
                          >
                            <option selected disabled>
                              ....
                            </option>
                            <option>Yes</option>
                            <option>No</option>
                          </select>
                          {errors.corn && (
                            <span className="text-danger">{errors.corn}</span>
                          )}
                        </div>
                      </div>

                      <div className="col-lg-3 col-md-3">
                        <div className="form-group">
                          <label>Energy:</label>
                          <select
                            className="form-control"
                            onChange={(e) => setEnergy(e.target.value)}
                          >
                            <option selected disabled>
                              ....
                            </option>
                            <option>1</option>
                            <option>2</option>
                            <option>3</option>
                            <option>4</option>
                            <option>5</option>
                          </select>
                          {errors.energy && (
                            <span className="text-danger">{errors.energy}</span>
                          )}
                        </div>
                      </div>
                      <div className="col-lg-3 col-md-3">
                        <div className="form-group">
                          <label>Clarity of Mind:</label>
                          <select
                            className="form-control"
                            onChange={(e) => setFocus(e.target.value)}
                          >
                            <option selected disabled>
                              ....
                            </option>
                            <option>1</option>
                            <option>2</option>
                            <option>3</option>
                            <option>4</option>
                            <option>5</option>
                          </select>
                          {errors.focus && (
                            <span className="text-danger">{errors.focus}</span>
                          )}
                        </div>
                      </div>
                      <div className="col-lg-3 col-md-3">
                        <div className="form-group">
                          <label>Happiness:</label>
                          <select
                            className="form-control"
                            onChange={(e) => setHappiness(e.target.value)}
                          >
                            <option selected disabled>
                              ....
                            </option>
                            <option>1</option>
                            <option>2</option>
                            <option>3</option>
                            <option>4</option>
                            <option>5</option>
                          </select>
                          {errors.happiness && (
                            <span className="text-danger">
                              {errors.happiness}
                            </span>
                          )}
                        </div>
                      </div>

                      <div className="col-lg-3 col-md-3">
                        <div className="form-group">
                          <label>Total Score:</label>
                          <input
                            value={addscore}
                            type="number"
                            className="form-control"
                            disabled
                          />
                        </div>
                      </div>
                      <div className="col-lg-6 col-md-6">
                        <div className="form-group">
                          <label>Elimination:</label>
                          <select
                            className="form-control"
                            onChange={(e) => setElimination(e.target.value)}
                          >
                            <option selected disabled>
                              ...
                            </option>
                            <option>0</option>
                            <option>1</option>
                            <option>2</option>
                            <option>2+</option>
                          </select>
                          {errors.elimination && (
                            <span className="text-danger">
                              {errors.elimination}
                            </span>
                          )}
                        </div>
                      </div>
                      <div className="col-lg-6 col-md-6">
                        <div className="form-group">
                          <label>Weight Each Day:</label>
                          <div className="row">
                            <div className="col-md-4">
                              <div className="form-group">
                                <select
                                  style={{ padding: 1 }}
                                  id="questions"
                                  type="text"
                                  className="form-control"
                                  onChange={(e) => setkg(e.target.value)}
                                >
                                  <option disabled selected>
                                    Select your Weight
                                  </option>
                                  <option>kg</option>
                                  <option>lb</option>
                                  <option>st</option>
                                  {/* <option>Stone</option>
                                <option>Ounce</option> */}
                                </select>
                                {errors.kg && (
                                  <span className="text-danger">
                                    {errors.kg}
                                  </span>
                                )}
                              </div>
                            </div>
                            <div className="col-md-8">
                              <div className="form-group">
                                <input
                                  type="number"
                                  className="form-control"
                                  onChange={(e) =>
                                    setWeightEachDay(e.target.value)
                                  }
                                />
                                {errors.weightEachDay && (
                                  <span className="text-danger">
                                    {errors.weightEachDay}
                                  </span>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-6 col-md-6">
                        <div className="form-group">
                          <label>Weight lost and Gain per day:</label>
                          <input
                            disabled
                            type="text"
                            className="form-control"
                            value={each_calplus ? each_calplus : each_cal}
                          />
                        </div>
                      </div>
                      <div className="col-lg-6 col-md-6">
                        <div className="form-group">
                          <label>Cumulative loss or gain:</label>
                          <input
                            disabled
                            type="text"
                            className="form-control"
                            value={
                              cumulativeplus ? cumulativeplus : firsteach_cal
                            }
                          />
                        </div>
                      </div>
                    </div>

                    <div className="row m-b30">
                      <div className="col-md-4" />
                      <div className="col-md-4">
                        <div className="form-group">
                          <button
                            className="site-button w-100 m-b30 "
                            type="button"
                            onClick={FeedBack}
                          >
                            Save Changes
                          </button>
                        </div>
                      </div>
                      <div className="col-md-4" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};
export default Feedbackform;
