import React, { useState } from "react";
import { Link, useHistory } from "react-router-dom";
import Header from "./../Layout/Header";
import Footer from "./../Layout/Footer";
import Profilesidebar from "../Element/Profilesidebar";
import axios from "axios";
import Swal from "sweetalert2";

function Changepasswordpage() {
  const history = useHistory();
  const [loader, setLoader] = useState("");
  const [enteremail, setEnteremail] = useState(true);
  const [enterotp, setEnterotp] = useState(false);
  const [enterpassword, setEnterpassword] = useState(false);
  const [getmail, setGetmail] = useState(false);
  const [email, setEmail] = useState("");

  const Mail = new FormData();

  const SendEmail = () => {
    setLoader(true);
    Mail.append("email", email);
    var config = {
      method: "post",
      url: `https://admin.the30dayweightloss.co.uk/api/user/forget`,
      data: Mail,
    };
    axios(config)
      .then(function (response) {
        setGetmail(response?.data);
        setLoader(false);
        if (response?.data?.status === true) {
          setEnteremail(false);
          setEnterotp(true);
          setEnterpassword(false);
          setLoader(false);
          Swal.fire({
            // // title: "Good job!",
            text: "A reset token has been emailed please use the rest number to create a new password. ",
            icon: "success",
            button: "Ok",
          });
        } else {
          setLoader(false);
          Swal.fire({
           // title: "Oppss!",
            text: response?.data?.message,
            icon: "error",
          });
        }
      })
      .catch((errors) => {
        setLoader(false);
        Swal.fire({
         // title: "Oppss!",
          text: errors,
          icon: "error",
          confirmButtonText: "Logout",
          confirmButtonColor: "red",
        }).then((result) => {
          if (result.isConfirmed) {
            localStorage.clear();
            setTimeout(() => {
              localStorage.clear();
              history.push("/login");
              window.location.reload();
            }, 5000);
          }
        });
      });
  };
  const Code = new FormData();
  const [token, setToken] = useState("");
  const [gettoken, setGet_Token] = useState("");
  const OtpCode = () => {
    setLoader(true);
    Code.append("token", token);
    var config = {
      method: "post",
      url: `https://admin.the30dayweightloss.co.uk/api/user/verify`,
      data: Code,
    };
    axios(config)
      .then(function (response) {
        setGet_Token(response?.data?.token);
        setLoader(false);
        if (response?.data?.status === true) {
          setEnteremail(false);
          setEnterotp(false);
          setEnterpassword(true);
          setLoader(false);
          Swal.fire({
            // title: "Good job!",
            text: response?.data?.message,
            icon: "success",
            button: "Ok",
          });
        } else {
          setLoader(false);
          Swal.fire({
           // title: "Oppss!",
            text: response?.data?.message,
            icon: "error",
          });
        }
      })
      .catch((errors) => {
        setLoader(false);
        Swal.fire({
         // title: "Oppss!",
          text: errors,
          icon: "error",
          confirmButtonText: "Logout",
          confirmButtonColor: "red",
        }).then((result) => {
          if (result.isConfirmed) {
            localStorage.clear();
            setTimeout(() => {
              localStorage.clear();
              history.push("/login");
              window.location.reload();
            }, 5000);
          }
        });
      });
  };
  const Password = new FormData();
  const [newpassword, setNewpassword] = useState("");
  const [confirmpassword, setConfirmpassword] = useState("");
  const UpdatePassword = () => {
    setLoader(true);
    Password.append("token", gettoken);
    Password.append("password", newpassword);
    Password.append("password_confirmation", confirmpassword);
    var config = {
      method: "post",
      url: `https://admin.the30dayweightloss.co.uk/api/user/reset/password`,
      data: Password,
    };
    axios(config)
      .then(function (response) {
        setToken(response?.data);
        setLoader(false);
        if (response?.data?.status === true) {
          history.push("/login");
          Swal.fire({
            // title: "Good job!",
            text: response?.data?.message,
            icon: "success",
            button: "Ok",
          });
        } else {
          setLoader(false);
          Swal.fire({
           // title: "Oppss!",
            text: response?.data?.message,
            icon: "error",
          });
        }
      })
      .catch((errors) => {
        setLoader(false);
        Swal.fire({
         // title: "Oppss!",
          text: errors,
          icon: "error",
          confirmButtonText: "Logout",
          confirmButtonColor: "red",
        }).then((result) => {
          if (result.isConfirmed) {
            localStorage.clear();
            setTimeout(() => {
              localStorage.clear();
              history.push("/login");
              window.location.reload();
            }, 5000);
          }
        });
      });
  };
  return (
    <>
      {loader ? <div className="loader"></div> : null}
      <Header />
      <div className="page-content bg-white">
        <div className="content-block">
          <div className="section-full bg-white browse-job p-t50 p-b20">
            <div className="container">
              <div className="row">
                <div className="col-xl-2 col-md-2" />
                <div className="col-xl-8 col-lg-8 m-b30 my-5">
                  <div className="job-bx job-profile">
                    <div className="job-bx-title clearfix">
                      <h5 className="font-weight-700 pull-left text-uppercase">
                        Forgot Password
                      </h5>
                    </div>
                    {enteremail ? (
                      <div className="row">
                        <div className="col-lg-12">
                          <div className="form-group">
                            <label>Enter Your Email</label>
                            <input
                              type="email"
                              className="form-control"
                              onChange={(e) => setEmail(e.target.value)}
                            />
                          </div>
                        </div>
                        <div className="col-lg-12 m-b10">
                          <button
                            className="site-button"
                            onClick={() => SendEmail()}
                          >
                            Send Email
                          </button>
                        </div>
                      </div>
                    ) : null}
                    {enterotp ? (
                      <div className="row">
                        <div className="col-lg-6">
                          <div className="form-group">
                            <label>Enter Your 4 digit Otp Code</label>
                            <input
                              type="number"
                              className="form-control"
                              onChange={(e) => setToken(e.target.value)}
                            />
                          </div>
                        </div>
                        <div className="col-lg-12 m-b10">
                          <button
                            className="site-button"
                            onClick={() => OtpCode()}
                          >
                            Change Password
                          </button>
                        </div>
                      </div>
                    ) : null}
                    {enterpassword ? (
                      <div className="row">
                        <div className="col-lg-6">
                          <div className="form-group">
                            <label>New Password </label>
                            <input
                              type="password"
                              className="form-control"
                              onChange={(e) => setNewpassword(e.target.value)}
                            />
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <div className="form-group">
                            <label>Confirm New Password</label>
                            <input
                              type="password"
                              className="form-control"
                              onChange={(e) =>
                                setConfirmpassword(e.target.value)
                              }
                            />
                          </div>
                        </div>
                        <div className="col-lg-12 m-b10">
                          <button
                            className="site-button"
                            onClick={() => UpdatePassword()}
                          >
                            Update Password
                          </button>
                        </div>
                      </div>
                    ) : null}
                  </div>
                </div>
                <div className="col-xl-2 col-md-2" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}
export default Changepasswordpage;
