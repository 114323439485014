/* eslint-disable jsx-a11y/img-redundant-alt */
import React, { useState } from "react";
import "react-phone-number-input/style.css";
import { Link, useHistory, useParams } from "react-router-dom";
import Header from "../Layout/Header";
import Footer from "../Layout/Footer";
import Profilesidebar from "../Element/Profilesidebar";
import Profilepic from "./../../images/team/pic1.jpg";
import Swal from "sweetalert2";
import axios from "axios";
import { useEffect } from "react";
import Dropzone from "../Element/Dropzonefile";
import { TagsInput } from "react-tag-input-component";
import PhoneInput from "react-phone-number-input";
import ReactDatePicker from "react-datepicker";

function Profile() {
  let store2 = localStorage.getItem("userDetails");
  store2 = JSON.parse(store2);
  const history = useHistory();
  const { id } = useParams();
  const [api, setApi] = useState();
  const [profile, setProfile] = useState();
  const [loader, setLoader] = useState(false);
  const [updatename, setupdatename] = useState("");
  const [updatetitle, setupdatetitle] = useState("");

  // Getting more Values
  const [fname, setFname] = useState();
  // Getting Valuesthe_ten_question

  const [img, setimg] = useState("");
  const [imgs, setimgs] = useState("");
  // Post Api
  // append to Form Data

  //  End Post Api
  // Get APi
  const Profile = () => {
    setLoader(true);
    let token = localStorage.getItem("userDetails");
    token = JSON.parse(token);
    var config = {
      method: "get",
      url: `https://admin.the30dayweightloss.co.uk/api/user/view/profile/${id}`,
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${token.token}`,
      },
    };

    axios(config)
      .then(function (response) {
        setProfile(response?.data?.data);
        console.log(profile);
        console.log(response?.data?.data?.cookies, "checking");
        localStorage.setItem("cookies", response?.data?.data?.cookies);
        if (response?.data?.status === true) {
          setLoader(false);
        } else {
          setLoader(false);
          Swal.fire({
            // title: "Oppss!",
            text: "Connection Failed!!",
            icon: "error",
            button: "Ok",
          });
        }
      })
      .catch((errors) => {
        setLoader(false);
        Swal.fire({
          // title: "Oppss!",
          text: errors,
          icon: "error",
          confirmButtonText: "Logout",
          confirmButtonColor: "red",
        }).then((result) => {
          if (result.isConfirmed) {
            localStorage.clear();
            setTimeout(() => {
              history.push("/login");
              window.location.reload();
            }, 1000);
          }
        });
        console.log(errors, "=========>errors");
      });
  };
  useEffect(() => {
    Profile();
  }, []);
  // End Get APi
  // DropZone
  const [images, setImages] = useState([]);
  console.log(images);

  return (
    <>
      {loader ? <div className="loader"></div> : null}
      <Header />

      <div className="page-content bg-white">
        <div className="content-block">
          <div className="section-full bg-white browse-job p-t50 p-b20">
            <div className="container-fluid" style={{ maxWidth: "80%" }}>
              <div className="row">
                <div className="col-xl-12 col-lg-12 m-b30">
                  <div className="job-bx job-profile">
                    <div className="job-bx-title clearfix">
                      <div className="row">
                        <div className="col-md-8">
                          <h5 className="font-weight-700  text-uppercase">
                            Personal Profile
                          </h5>
                        </div>
                        <div className=" col-md-4">
                          <div className="text-center bottom">
                            <Link
                              to={`/profileedit/${store2?.data.id}`}
                              className="site-button right-arrow button-sm float-right"
                            >
                              Edit
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row m-b30">
                      <div className="col-lg-6 col-md-6">
                        <div className="form-group">
                          <label>
                            First Name:<span className="text-danger">*</span>
                          </label>
                          <input
                            disabled
                            value={profile?.first_name}
                            type="text"
                            className="form-control"
                          />
                        </div>
                      </div>
                      <div className="col-lg-6 col-md-6">
                        <div className="form-group">
                          <label>
                            Last Name:<span className="text-danger">*</span>
                          </label>
                          <input
                            disabled
                            value={profile?.last_name}
                            type="text"
                            className="form-control"
                          />
                        </div>
                      </div>
                      <div className="col-lg-12 col-md-12">
                        <div className="form-group">
                          <label>
                            Email:<span className="text-danger">*</span>
                          </label>
                          <input
                            disabled
                            value={profile?.email}
                            type="text"
                            className="form-control"
                          />
                        </div>
                      </div>
                      <div className="col-md-9">
                        <div className="form-group">
                          <label>
                            Address:<span className="text-danger">*</span>
                          </label>
                          <input
                            disabled
                            type="text"
                            className="form-control"
                            value={profile?.address}
                          />
                        </div>
                      </div>
                      <div className="col-md-3">
                        <div className="form-group">
                          <label>
                            PostCode:
                            <span className="text-danger">*</span>
                          </label>
                          <input
                            type="tel"
                            className="form-control"
                            value={profile?.post_code}
                            disabled
                          />
                        </div>
                      </div>
                      <div className="col-lg-4 col-md-4">
                        <div className="form-group">
                          <label>
                            Gender:<span className="text-danger">*</span>
                          </label>
                          <input
                            disabled
                            value={profile?.gender}
                            className="form-control"
                          />
                        </div>
                      </div>

                      <div className="col-lg-4 col-md-4">
                        <div className="form-group">
                          <label>
                            Weight:<span className="text-danger">*</span>
                          </label>
                          <input
                            disabled
                            type={"text"}
                            value={
                              profile?.weight + profile?.kg
                                ? profile?.weight + " " + profile?.kg
                                : "Null"
                            }
                            className="form-control"
                          />
                        </div>
                      </div>
                      <div className="col-lg-4 col-md-4">
                        <div className="form-group">
                          <label>
                            Age:<span className="text-danger">*</span>
                          </label>
                          <input
                            disabled
                            value={profile?.age}
                            type="number"
                            className="form-control"
                          />
                        </div>
                      </div>
                      {profile?.gender === "Male" ? null : (
                        <div className="col-lg-12 col-md-12">
                          <div className="form-group">
                            <label>
                              Pregnant:<span className="text-danger">*</span>
                            </label>
                            <input
                              type="text"
                              disabled
                              value={profile?.pregnant}
                              className="form-control"
                            />
                          </div>
                        </div>
                      )}

                      <div className="col-lg-6 col-md-6">
                        <div className="form-group">
                          <label>Existing Medication:</label>

                          <TagsInput
                            international
                            // countryCallingCodeEditable={false}
                            // defaultCountry="RU"
                            // placeholder="Enter phone number"
                            disabled
                            value={profile?.existing_medication?.split(",")}
                          />
                        </div>
                      </div>
                      <div className="col-lg-6 col-md-6">
                        <div className="form-group">
                          <label>Allergies:</label>
                          <TagsInput
                            disabled
                            value={profile?.allergies?.split(",")}
                          />
                        </div>
                      </div>

                      <div className="col-lg-6 col-md-6">
                        <div className="form-group">
                          <label>
                            Phone:<span className="text-danger">*</span>
                          </label>
                          <PhoneInput
                            disabled
                            value={profile?.phone_no}
                            style={{
                              height: 50,
                              borderRadius: 5,
                              marginRight: 25,
                              backgroundColor: "#fff",
                              border: 0,
                            }}
                          />
                        </div>
                      </div>
                      {/* <div className="col-lg-6 col-md-6">
                        <div className="form-group">
                          <label>
                            Date of Brith:<span className="text-danger">*</span>
                          </label>
                          <input
                            disabled
                            dateFormat="MM/yyyy/dd"
                            value={profile?.birth_within_months}
                            type="text"
                            className="form-control"
                          />
                        </div>
                      </div> */}
                      {/* <div className="col-lg-6 col-md-6">
                        <div className="form-group">
                          <label>Picture Both Hands:</label>
                          <div className="row">
                            {profile?.picture_both_hands
                              ?.split(",")
                              .map((e) => {
                                return (
                                  <>
                                    <div className="col-md-6">
                                      <img
                                        src={`https://weightlossadmin.the30dayweightloss.com/images/${e}`}
                                        alt="no Image"
                                        className="rounded mt-3 mb-1 img-circle img-thumbnail"
                                        style={{ height: 99 }}
                                      />
                                    </div>
                                  </>
                                );
                              })}
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-6 col-md-6">
                        <div className="form-group">
                          <label>Pull Length Picture:</label>
                          <div className="row">
                            <div className="col-md-12">
                              <img
                                src={`https://weightlossadmin.the30dayweightloss.com/uploads/images/${profile?.pull_length_picture}`}
                                className="rounded mt-3 mb-1 img-circle img-thumbnail"
                                style={{ height: 99 }}
                                alt="no Image"
                              />
                            </div>
                          </div>
                        </div>
                      </div> */}
                      {/* <div className="col-lg-6 col-md-6">
                        <div className="form-group">
                          <label>The Ten Question:</label>
                          <input
                            disabled
                            value={profile?.the_ten_question}
                            id="questions"
                            type="text"
                            className="form-control"
                          />
                        </div>
                      </div> */}
                      <div className="col-lg-6 col-md-6">
                        <div className="form-group">
                          <label>
                            <label>
                              Your Mind-Body Type:
                              <span className="text-danger">*</span>
                              View the home page video if you haven't done it
                              yet
                            </label>
                          </label>
                          <input
                            disabled
                            value={profile?.your_mind_body}
                            placeholder="eg:7-2-1"
                            type="text"
                            className="form-control"
                          />
                        </div>
                      </div>
                      <div className="col-lg-6 col-md-6">
                        <div className="form-group">
                          <label>Your Mind-BodyType :</label>

                          <input
                            disabled
                            value={profile?.secret_predominant}
                            type="text"
                            className="form-control"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-6">
                        <div className="form-group">
                          <span>
                            <input
                              type="checkbox"
                              checked={profile?.agree === "true" ? true : false}
                            />
                            &nbsp;&nbsp;
                            <label className="text-muted">
                              I agree to the{" "}
                              <Link
                                to={"/Term&Conditions"}
                                target="_blank"
                                className="text-primary"
                                style={{ color: "blue" }}
                              >
                                <u>
                                  <b>terms and conditions</b>
                                </u>
                              </Link>
                              <label className="text-muted">
                                You must click this link to be able to agree to
                                terms and conditions.
                              </label>
                            </label>
                          </span>{" "}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}
export default Profile;
