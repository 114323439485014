import axios from "axios";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { Table } from "react-bootstrap";
import ReactHtmlTableToExcel from "react-html-table-to-excel";
import { FaPrint } from "react-icons/fa";
import { Link, useHistory, useParams } from "react-router-dom";
import Swal from "sweetalert2";
import Profilesidebar from "../Element/Profilesidebar";
import Footer from "../Layout/Footer";
import Header from "../Layout/Header";

const Chart = () => {
  const history = useHistory();
  const Quetions = [
    "Refined Sugar",
    "Gluten",
    "Dairy",
    "Corn Syrup",
    "Energy",
    "Clarity of Mind",
    "Happiness",
    "Total Score ",
    "Elimination",
    "Weight each day",
    "Weight lost per day",
    "Cumulative loss or gain",
  ];
  const { id } = useParams();
  let local = localStorage.getItem("userDetails");
  local = JSON.parse(local);

  const [loader, setLoader] = useState(false);
  const [api, setApi] = useState([]);
  let store2 = localStorage.getItem("userDetails");
  store2 = JSON.parse(store2);
  const Chartdata = () => {
    setLoader(true);
    let token = localStorage.getItem("userDetails");
    token = JSON.parse(token);
    var config = {
      method: "get",
      url: `https://admin.the30dayweightloss.co.uk/api/user/feedback/view/${store2?.data?.id}`,
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${token.token}`,
      },
    };

    axios(config)
      .then(function (response) {
        setLoader(true);
        setApi(response?.data?.data);
        console.log(response?.data?.data[0], "checking");
        if (response?.data?.status === true) {
          setLoader(false);
        } else {
          setLoader(false);
          Swal.fire({
           // title: "Oppss!",
            text: "Connection Failed!!",
            icon: "error",
            button: "Ok",
          });
        }
      })
      .catch((errors) => {
        setLoader(false);
        Swal.fire({
         // title: "Oppss!",
          text: errors,
          icon: "error",
          confirmButtonText: "Logout",
          confirmButtonColor: "red",
        }).then((result) => {
          if (result.isConfirmed) {
            localStorage.clear();
            setTimeout(() => {
              history.push("/login");
              window.location.reload();
            }, 1000);
          }
        });
        console.log(errors, "=========>errors");
      });
  };
  useEffect(() => {
    Chartdata();
  }, []);
  console.log(api, "========11");

  return (
    <>
      {loader ? <div className="loader"></div> : null}
      <Header />
      <div className="page-content bg-white">
        <div className="content-block">
          <div className="section-full bg-white browse-job p-t50 p-b20">
            <div className="container-fluid" style={{ maxWidth: "80%" }}>
              <div className="row">
                <div className="col-xl-12 col-lg-12 m-b30">
                  <div className="job-bx job-profile">
                    <div className="job-bx-title clearfix">
                      <div className="row">
                        <div className="col-md-8">
                          <h5 className="font-weight-700 font text-uppercase">
                            {local?.data?.first_name}'s FeedBack Chart
                          </h5>
                        </div>
                        <div className=" col-md-4">
                          <div className="text-center bottom">
                            <Link
                              to={`/FeedBackForm/${store2?.data?.id}`}
                              className="site-button right-arrow button-sm float-right"
                            >
                              Back
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row my-2">
                      <div className="col-md-4" />
                      <div className="col-md-4" />
                      <div className="col-md-4">
                        <ReactHtmlTableToExcel
                          id="test-table-xls-button"
                          className="btn-success btn-lg float-right fa fa-print"
                          table="table-to-xls"
                          filename={local?.data?.first_name + " feedbackchart"}
                          sheet="tablexls"
                          buttonText=" Export XLS"
                        />
                      </div>
                    </div>
                    <div className="row m-b30 " style={{ overflow: "auto" }}>
                      <table
                        style={{ width: "100%", backgroundColor: "white" }}
                        cellpadding="12"
                        cellspacing="0"
                        id="table-to-xls"
                      >
                        <tr style={{ border: "1px solid" }}>
                          <td
                            style={{
                              border: "1px solid",
                              width: "7.6%",
                              color: "black",
                              textAlign: "center",
                              fontSize: 13,
                            }}
                          >
                            Days
                          </td>
                          {Quetions.map((e) => {
                            return (
                              <>
                                <td
                                  style={{
                                    border: "1px solid",
                                    width: "7.6%",
                                    color: "black",
                                    textAlign: "center",
                                    fontSize: 13,
                                  }}
                                >
                                  {e}
                                </td>
                              </>
                            );
                          })}
                        </tr>
                        {api?.map((e, i) => {
                          return (
                            <>
                              <tr style={{ border: "1px solid" }}>
                                <td
                                  style={{
                                    border: "1px solid",
                                    width: "7.6%",
                                    color: "black",
                                    fontSize: "12px",
                                  }}
                                >
                                  {i + 1}{" "}
                                </td>
                                <td
                                  style={{
                                    border: "1px solid",
                                    width: "7.6%",
                                    color: "black",
                                    fontSize: "12px",
                                  }}
                                >
                                  {e?.refined_sugar
                                    ? e?.refined_sugar
                                    : "No Data "}
                                </td>
                                <td
                                  style={{
                                    border: "1px solid",
                                    width: "7.6%",
                                    color: "black",
                                    fontSize: "12px",
                                  }}
                                >
                                  {e?.gluten ? e?.gluten : "No Data"}
                                </td>
                                <td
                                  style={{
                                    border: "1px solid",
                                    width: "7.6%",
                                    color: "black",
                                    fontSize: "12px",
                                  }}
                                >
                                  {e?.dairy ? e?.dairy : "No Data"}
                                </td>
                                <td
                                  style={{
                                    border: "1px solid",
                                    width: "7.6%",
                                    color: "black",
                                    fontSize: "12px",
                                  }}
                                >
                                  {e?.corn_syrup ? e?.corn_syrup : "No Data"}
                                </td>
                                <td
                                  style={{
                                    border: "1px solid",
                                    width: "7.6%",
                                    color: "black",
                                    fontSize: "12px",
                                  }}
                                >
                                  {e?.corn_syrup ? e?.energy : "No Data"}
                                </td>
                                <td
                                  style={{
                                    border: "1px solid",
                                    width: "7.6%",
                                    color: "black",
                                    fontSize: "12px",
                                  }}
                                >
                                  {e?.focus ? e?.focus : "No Data"}
                                </td>
                                <td
                                  style={{
                                    border: "1px solid",
                                    width: "7.6%",
                                    color: "black",
                                    fontSize: "12px",
                                  }}
                                >
                                  {e?.happiness ? e?.happiness : "No Data"}
                                </td>
                                <td
                                  style={{
                                    border: "1px solid",
                                    width: "7.6%",
                                    color: "black",
                                    fontSize: "12px",
                                  }}
                                >
                                  {e?.total_score ? e?.total_score : "No Data"}
                                </td>
                                <td
                                  style={{
                                    border: "1px solid",
                                    width: "7.6%",
                                    color: "black",
                                    fontSize: "12px",
                                  }}
                                >
                                  {e?.elimination ? e?.elimination : "No Data"}
                                </td>
                                <td
                                  style={{
                                    border: "1px solid",
                                    width: "7.6%",
                                    color: "black",
                                    fontSize: "12px",
                                  }}
                                >
                                  {e?.weight_each_day
                                    ? e?.weight_each_day + " " + e?.kg
                                    : "No Data"}
                                </td>
                                <td
                                  style={{
                                    border: "1px solid",
                                    width: "7.6%",
                                    color: "black",
                                    fontSize: "12px",
                                  }}
                                >
                                  {e?.weight_lost_per_day
                                    ? e?.weight_lost_per_day
                                    : "No Data"}
                                </td>
                                <td
                                  style={{
                                    border: "1px solid",
                                    width: "7.6%",
                                    color: "black",
                                    fontSize: "12px",
                                  }}
                                >
                                  {e?.cumulative_loss_or_gain
                                    ? e?.cumulative_loss_or_gain
                                    : "No Data"}
                                </td>
                              </tr>
                            </>
                          );
                        })}
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};
export default Chart;
