import React, { useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import Header from "./../Layout/Header";
import Footer from "./../Layout/Footer";
import PageTitle from "./../Layout/PageTitle";
import GoogleMaps from "simple-react-google-maps";
import { useState } from "react";
import axios from "axios";
import Swal from "sweetalert2";
import Profilesidebar from "../Element/Profilesidebar";

var bnr = require("./../../images/banner/bnr1.jpg");

function Contact() {
  let store2 = localStorage.getItem("userDetails");
  store2 = JSON.parse(store2);
  const history = useHistory();
  const [profile, setProfile] = useState();
  const [loader, setLoader] = useState(false);
  const [ticket, setticket] = useState();
  const [ID, setID] = useState();
  //Getting Values
  const [email, setEmail] = useState("");
  const [first_name, setFirstname] = useState("");
  const [last_name, setLastname] = useState("");
  const [phone_no, setPhonenumber] = useState("");
  const [checking, setchecking] = useState("");
  const [checked, setChecked] = useState("");
  const [message, setMessage] = useState("");
  const [title, setTitle] = useState("");
  const [get_profile, setGet_profile] = useState("");
  console.log(message);
  const IsChecked = (e) => {
    const value = e.target.value;
    const check = e.target.checked;

    if (check) {
      setChecked([...checked, value]);
      setchecking(check);
    } else {
      setChecked(checked.filter((e) => e !== value));
      setchecking(check);
    }
    console.log(check, "<===Ischecked");
  };
  // Append

  const Contactus = () => {
    setLoader(true);

    const Contact = new FormData();
    Contact.append(
      "first_name",
      first_name ? first_name : get_profile?.first_name
    );
    Contact.append("last_name", last_name ? last_name : get_profile?.last_name);
    Contact.append("email", email ? email : get_profile?.email);
    Contact.append("phone_no", phone_no ? phone_no : get_profile?.phone_no);
    Contact.append("message", message);
    Contact.append("title", title);
    Contact.append("agree", checking);

    var config = {
      method: "post",
      url: `https://admin.the30dayweightloss.co.uk/api/user/support/ticket`,
      data: Contact,
      // headers: {
      //   Accept: "application/json",
      //   Authorization: `Bearer ${token.token}`,
      // },
    };

    axios(config)
      .then(function (response) {
        setticket(response?.data);
        setLoader(false);
        if (response?.data?.status === true) {
          setLoader(false);
          Swal.fire({
            // title: "Good job!",
            text: "Support Request Submitted",
            icon: "success",
            button: "Ok",
          });
        } else {
          setLoader(false);
          Swal.fire({
            // title: "Oppss!",
            text: "Check Your Fields",
            icon: "error",
            button: "Ok",
          });
        }
      })
      .catch((errors) => {
        setLoader(false);
        Swal.fire({
          // title: "Oppss!",
          text: errors,
          icon: "error",
          confirmButtonText: "Logout",
          confirmButtonColor: "red",
        }).then((result) => {
          if (result.isConfirmed) {
            localStorage.clear();
            setTimeout(() => {
              history.push("/login");
              window.location.reload();
            }, 1000);
          }
        });
        console.log(errors, "=========>errors");
      });
  };
  const Profile = () => {
    setLoader(true);
    let token = localStorage.getItem("userDetails");
    token = JSON.parse(token);
    var config = {
      method: "get",
      url: `https://admin.the30dayweightloss.co.uk/api/user/view/profile/${store2?.data?.id}`,
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${token.token}`,
      },
    };

    axios(config)
      .then(function (response) {
        setGet_profile(response?.data?.data);
        console.log(get_profile);
        console.log(response, "checking");
        if (response?.data?.status === true) {
          setLoader(false);
        } else {
          setLoader(false);
          Swal.fire({
            // title: "Oppss!",
            text: "Connection Failed!!",
            icon: "error",
            button: "Ok",
          });
        }
      })
      .catch((errors) => {
        setLoader(false);
        Swal.fire({
          // title: "Oppss!",
          text: errors,
          icon: "error",
          confirmButtonText: "Logout",
          confirmButtonColor: "red",
        }).then((result) => {
          if (result.isConfirmed) {
            localStorage.clear();
            setTimeout(() => {
              history.push("/login");
              window.location.reload();
            }, 1000);
          }
        });
        console.log(errors, "=========>errors");
      });
  };
  useEffect(() => {
    if (localStorage.getItem("userDetails")) {
      Profile();
    }
  }, []);
  return (
    <>
      {loader ? <div className="loader"></div> : null}
      <Header />
      <div className="page-content bg-white">
        {/* <div
          className="dez-bnr-inr overlay-black-middle"
          style={{ backgroundImage: "url(" + bnr + ")" }}
        >
          <PageTitle motherName="Home" activeName="Contact Us" />
        </div> */}
        <div className="section-full content-inner bg-white contact-style-1">
          <div className="container-fluid" style={{ maxWidth: "80%" }}>
            <div className="row">
              <div className="col-lg-12 col-md-12">
                <div className="p-a30 m-b30 radius-sm bg-gray clearfix">
                  <h4>Submit a Support Ticket</h4>

                  <div className="row">
                    <div className="col-lg-6">
                      <div className="form-group">
                        <div className="input-group">
                          <input
                            type="text"
                            required
                            className="form-control"
                            placeholder="Your Name"
                            value={get_profile?.first_name}
                            onChange={(e) => setFirstname(e.target.value)}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="form-group">
                        <div className="input-group">
                          <input
                            type="text"
                            className="form-control"
                            required
                            placeholder="Your Last Name"
                            value={get_profile?.last_name}
                            onChange={(e) => setLastname(e.target.value)}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="form-group">
                        <div className="input-group">
                          <input
                            type="tel"
                            className="form-control"
                            required
                            placeholder="Your Phone Number"
                            value={get_profile?.phone_no}
                            onChange={(e) => setPhonenumber(e.target.value)}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="form-group">
                        <div className="input-group">
                          <input
                            type="email"
                            className="form-control"
                            required
                            placeholder="Your Email"
                            value={get_profile?.email}
                            onChange={(e) => setEmail(e.target.value)}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-12">
                      <div className="form-group">
                        <div className="input-group">
                          <input
                            type="text"
                            className="form-control"
                            required
                            placeholder="Your title"
                            onChange={(e) => setTitle(e.target.value)}
                          />
                        </div>
                      </div>
                    </div>

                    <div className="col-lg-12">
                      <div className="form-group">
                        <div className="input-group">
                          <textarea
                            rows="4"
                            className="form-control"
                            required
                            placeholder="Your Message..."
                            onChange={(e) => setMessage(e.target.value)}
                          ></textarea>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <input
                          type="checkbox"
                          onChange={IsChecked}
                          style={{ height: 13 }}
                        />
                        &nbsp;&nbsp;
                        <label className="text-muted">Request a Call </label>
                      </div>
                    </div>
                    <div className="offset-md-5 col-lg-2 offset-md-5">
                      <button onClick={Contactus} className="site-button ">
                        {" "}
                        <span>Submit</span>{" "}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default Contact;
