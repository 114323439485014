import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Modal } from "react-bootstrap";
import Logout from "./Logout";
import { useState } from "react";
import { useEffect } from "react";

import logo2 from "./../../images/weight.png";
var bnr3 = require("./../../images/background/bg3.jpg");

export const Header2 = () => {
  // state = {
  // 	// initial state
  // 	show: false,
  // }
  const [close, setClose] = useState(false);
  const [show, setShow] = useState(false);
  const handleClose = () => {
    setClose(false);
  };
  const handleShow = () => {
    setShow(true);
  };
  useEffect(() => {
    // sidebar open/close

    var Navicon = document.querySelector(".navicon");
    var sidebarmenu = document.querySelector(".myNavbar ");

    function toggleFunc() {
      sidebarmenu.classList.toggle("show");
      //   Navicon.classList.toggle('open');
    }
    Navicon.addEventListener("click", toggleFunc);

    // Sidenav li open close
    var navUl = [].slice.call(
      document.querySelectorAll(".navbar-nav > li > a, .sub-menu > li > a")
    );
    for (var y = 0; y < navUl.length; y++) {
      navUl[y].addEventListener("click", function () {
        checkLi(this);
      });
    }

    function checkLi(current) {
      current.parentElement.parentElement
        .querySelectorAll("li")
        .forEach((el) =>
          current.parentElement !== el ? el.classList.remove("open") : ""
        );
      setTimeout(() => {
        current.parentElement.classList.toggle("open");
      }, 100);
    }
  });

  return (
    <>
      <header className="site-header mo-left header fullwidth">
        <div className="sticky-header main-bar-wraper navbar-expand-lg">
          <div className="main-bar clearfix">
            <div
              className="container-fluid clearfix"
              style={{ maxWidth: "95%" }}
            >
              <div className="logo-header mostion">
                <Link to={"#"}>
                  <img
                    src={logo2}
                    className="logo mb-1"
                    width="100%"
                    height="80px"
                    style={{ height: 80, maxWidth: 350 }}
                    alt="img"
                  />
                </Link>
              </div>

              <button
                className="navbar-toggler collapsed navicon  justify-content-end"
                type="button"
                data-toggle="collapse"
                data-target="#navbarNavDropdown"
                aria-controls="navbarNavDropdown"
                aria-expanded="false"
                aria-label="Toggle navigation"
              >
                <span></span>
                <span></span>
                <span></span>
              </button>
              <div className="extra-nav">
                <div className="extra-cell">
                  <Logout class="site-button" />
                </div>
              </div>

              <div
                className="header-nav navbar-collapse collapse myNavbar justify-content-start"
                id="navbarNavDropdown"
              >
                <div className="logo-header mostion d-md-block d-lg-none">
                  <Link to={"/"} className="dez-page">
                    <img
                      src={logo2}
                      alt=""
                      height="80px"
                      style={{ height: 80, maxWidth: 350 }}
                    />
                  </Link>
                </div>
                {/* <ul className="nav navbar-nav">
                  <li className="">
                    <Link to="#" className="">
                      Service
                      <i className="fa fa-chevron-down"></i>
                    </Link> */}
                {/* <ul className="sub-menu">
                      <li>
                        <Link to={"/Attorney"} className="dez-page">
                          ATTORNEY
                        </Link>
                      </li>
                      <li>
                        <Link to={"/Accoctant"} className="dez-page">
                          ACCOUNTANT{" "}
                        </Link>
                      </li>
                      <li>
                        <Link to={"/Advisor"} className="dez-page">
                          ADVISOR{" "}
                        </Link>
                      </li>
                    </ul> */}
                {/* </li>
                  <li>
                    <Link to={"#"}>About us</Link>
                  </li> */}
                {/* <li>
                    <Link to={"/contact"}>Contact us</Link>
                  </li> */}
                {/* </ul> */}
              </div>
            </div>
          </div>
        </div>
      </header>
    </>
  );
};

export default Header2;
