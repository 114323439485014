import React, { useEffect } from "react";
import { Form } from "react-bootstrap";
import { FaCoffee, FaQuestion } from "react-icons/fa";
import { Link } from "react-router-dom";
// import swal from "sweetalert";
import Jobsection from "../Element/Jobsection";
import Footer from "../Layout/Footer";
import Header from "../Layout/Header";
// ES6 Modules or TypeScript
import Swal from "sweetalert2";
import swal from "sweetalert";

export const Accoctant = () => {
  // CommonJS
  const Swal = require("sweetalert2");
  // const myTimeout = setTimeout(Helpme, 5000);
  const Helpme = () => {
    Swal.fire({
      icon: "question",
      title: "TO BE CONSIDRED, YOU MUST HAVE:",
      html: "PASSION FOR PROVIDING GREAT CLIENTS SERVICES <br> <br> A SPOTLESS STATE RECORD AND ONLINE REPUTATION THE ABILITY TO EXPLAIN COMPLEX ACCOUNTING ISSUES IN SIMPLE WAYS <br> <br>  A SPOTLESS STATE BAR RECORD AND ONLINE REPUTATION THE ABILITY TO EXPLAIN COMPLEX LEGAL ISSUES IN SIMPLE WAYS <br> <br> EXPERIENCE WITH TECHNOLOGY, AND CONFORT WITH VIRTUAL COLLABORATION",
      confirmButtonText: "UnderStand",
    });
  };
  useEffect(() => {
    const timer = setTimeout(() => {
      Swal.fire({
        icon: "question",
        title: "TO BE CONSIDRED, YOU MUST HAVE:",
        html: "PASSION FOR PROVIDING GREAT CLIENTS SERVICES <br> <br> A SPOTLESS STATE RECORD AND ONLINE REPUTATION THE ABILITY TO EXPLAIN COMPLEX ACCOUNTING ISSUES IN SIMPLE WAYS <br> <br>  A SPOTLESS STATE BAR RECORD AND ONLINE REPUTATION THE ABILITY TO EXPLAIN COMPLEX LEGAL ISSUES IN SIMPLE WAYS <br> <br> EXPERIENCE WITH TECHNOLOGY, AND CONFORT WITH VIRTUAL COLLABORATION",
        confirmButtonText: "UnderStand",
      });
    }, 1000);
    return () => clearTimeout(timer);
  }, []);
  return (
    <>
      <div className="page-wraper">
        <Header />
        <div className="page-content">
          <Jobsection name="Accountant" />
          <div className="page-content bg-white">
            <div className="content-block">
              <div className="section-full bg-white p-t50 p-b20">
                <div className="container">
                  <div className="row">
                    <div className="col-lg-12 m-b30">
                      <div className="job-bx submit-resume">
                        <div className="job-bx-title clearfix">
                          <h5 className="font-weight-700 pull-left text-uppercase">
                            PLEASE COMPLETE THIS FORM TO COMPLETE YOUR INTEREST
                          </h5>
                          <button
                            title="Help Me"
                            type="button"
                            className="float-right rec text-center"
                            onClick={Helpme}
                          >
                            <FaQuestion />
                          </button>
                        </div>
                        <form>
                          <div className="row">
                            <div className="col-lg-6 col-md-6">
                              <div className="form-group">
                                <label>Your Name</label>
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="Enter Job Title"
                                />
                              </div>
                            </div>
                            <div className="col-lg-6 col-md-6">
                              <div className="form-group">
                                <label>Firm Name</label>
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="info@gmail.com"
                                />
                              </div>
                            </div>
                            <div className="col-lg-6 col-md-6">
                              <div className="form-group">
                                <label>Email</label>
                                <input
                                  type="email"
                                  className="form-control"
                                  placeholder="info@gmail.com"
                                />
                              </div>
                            </div>
                            <div className="col-lg-6 col-md-6">
                              <div className="form-group">
                                <label>WebSite</label>
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="info@gmail.com"
                                />
                              </div>
                            </div>
                            <div className="col-lg-6 col-md-6">
                              <div className="form-group">
                                <label>Law Firm Size</label>
                                <Form.Control
                                  as="select"
                                  custom
                                  className="custom-select"
                                >
                                  <option selected disabled>
                                    Select Your Firm Size
                                  </option>
                                  <option>Option 1</option>
                                  <option>Option 2</option>
                                  <option>Option 3</option>
                                </Form.Control>
                              </div>
                            </div>
                            <div className="col-lg-6 col-md-6">
                              <div className="form-group">
                                <label>State Licensed</label>
                                <Form.Control
                                  as="select"
                                  custom
                                  className="custom-select"
                                >
                                  <option>1 </option>
                                  <option>2 </option>
                                  <option>3 </option>
                                  <option>4 </option>
                                  <option>5 </option>
                                </Form.Control>
                              </div>
                            </div>
                          </div>
                          <button type="button" className="site-button m-b30">
                            Submit
                          </button>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
};
